import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Alpaca's Blog
        </div>
        <h1 className={cn("h1", styles.title)}>
        Mental health learnings from Jonah Hill’s movie “Stutz”
        </h1>
        <div className={cn("h5", styles.stage)}>
          FEBRUARY 09, 2023 • 4 MIN
        </div>

        <div className={styles.content}>
          <div>
          Some of our team members recently watched Jonah Hill’s new movie “Stutz” on Netflix. In the movie, Jonah interviews his therapist, Phil Stutz, about his perspectives on, and tools for, improving mental health. Jonah credits Stutz for helping him drastically improve his mental health over the years and wanted to share some of the most important lessons he has learned with a broader audience in the hope that it helps them too.
          </div>
          <br />
          <div>Disclaimer: This is not a film review - we’re definitely not qualified for that! We recently posted a <a href="https://twitter.com/Alpaca_Health/status/1612982260118192128?s=20&t=BeNLk59IZVfJV7vaUZEGAA" target="_blank">thread on Twitter</a> about some of the key learnings from the film, especially for folks struggling physically and mentally with Long COVID, ME/CFS, POTS, and other draining chronic illnesses (comment, like, and retweet if you want to share the love!) Based on the positive feedback, we’ve expanded on those tenets a little in this blog post. It is broken down into three sections to make it more digestible - 1. Gaining Perspective; 2. Taking Action; and 3. Galvanizing Will. We hope you find it helpful!</div>
          <br />
          <div className={cn("h5", styles.section)}>
          SECTION 1: GAINING PERSPECTIVE
          </div>
          <b>1A. Part X:</b> We have an invisible force that wants to prevent us from growing and succeeding in life - it wants to stuff everything up; everyone struggles with Part X; it comes and it goes but without it there would be no progress.
          <br />
          <br />
          <i>Illness is a brutal manifestation of Part X but with time – and the right balance of rest, pacing, effort, and good fortune – it too can help you gain perspective and grow.</i>
          <br />
          <br />
          <b>1B. Realm of illusion (the snapshot):</b> The image of success we’re fixated on – the perfect health, partner, house, or amount of money; it has no depth or linear path of time; it is a surface-level false reality created by Part X to torment us.
          <br />
          <br />
          <i>Don’t cling to snapshots of “perfect” and forget to live (and enjoy!) the realities of today; if you’re not well, this becomes even more important; start small – enjoy watching the sun rise or set, go for a light stroll or sit calmly in nature. Accepting and enjoying your reality is the pathway to managing your fixations.</i>
          <br />
          <br />
          <b>1C. The maze:</b> Our attachment to Part X’s “snapshot” can often leave us disillusioned and stuck with negative thoughts and habits; everything in life – the light and the dark – is a passage of time and effort.
          <br />
          <br />
          <i>Our health is never constant or perfect but there will be times when you feel healthier and with a greater sense of control – enjoy those moments but try not to stress if it feels like you’re lost in the maze; a better feeling can return with time but will rarely live up to any illusions of “perfect”.</i>
          <br />
          <br />
          <b>1D. Our shadow:</b> Our lowest self-image; a mental image of ourselves that we want to hide from the world; it causes a “black cloud” to come over our self-worth.
          <br />
          <br />
          <i>Our shadow may be related to how we see ourselves struggling with illness – the person who hasn’t spent any meaningful time out of bed or off the couch for weeks, months or years; don’t be ashamed – many are grappling with a similar illness-affected self-image; embrace your shadow, talk with it, and seek to understand what it is saying to you.</i>
          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          Jonah Hill's NetFlix Documentary "Stutz" - candid conversations with actor Jonah Hill and leading psychiatrist Phil Stutz explores his early life experiences and unique, visual model of therapy. 
          </div>
          <div className={styles.preview}>
            <img
              src="/images/content/jonah.jpeg"
              alt="Jonah Hill's Stutz on NetFlix"
              />
          </div>
          <div className={cn("h5", styles.section)}>
          SECTION 2: TAKING ACTION
          </div>
          <b>2A. Life force:</b> Everything starts with nurturing our whole-body health – physical (sleep, exercise, diet, stress, toxins), social (vulnerability and meaningful engagement with others), and mental (self-reflection and journaling to understand our inner dialogue).
          <br />
          <br />
          <i>When grappling with illness, regaining your life force may look a little different but it has never been more important; pace yourself, be patient, be kind, and be honest with yourself and those around you.</i>
          <br />
          <br />
          <b>2B. String of pearls:</b> Envisage a pearl necklace – each pearl is a positive action, our resolve to move forward in spite of Part X; most of our pearls will have blemishes – rarely will actions be perfect but our goal is to keep moving forward, not to critique prior actions.
          <br />
          <br />
          <i>Your pearls of action might seem more trivial when facing illness, but they are none-the-less valuable; an action may be saying “thank you” or choosing to look at something a different way, it also might be choosing to rest and pace yourself to manage your energy levels.</i>
          <br />
          <br />
          <b>2C. Active love:</b> Harness the warmth of love; imagine the universe is dense with love energy – it is impossible to avoid it; focus on this to move forward from the maze.
          <br />
          <br />
          <i>Living with an ongoing illness can be draining and isolating; be sure to manifest love for yourself and others, and try not to push others away from providing love and support; there are lots of people and communities who want to help.</i>
          <br />
          <br />
          <b>2D: Loss processing:</b> The power of non-attachment; imagine grasping onto the thing/s you’re fixated on like a branch on a tree; say “I’m willing to lose everything” and let go; feel yourself fall through the sky and burn up in the sun; the goal is not to become completely unattached, but rather to work towards not allowing things to take control of you.
          <br />
          <br />
          <i>It is easy to become fixated on what you don’t have in life; although it may seem quirky, try the above exercise a few times – perhaps try to detach from a fixation on wanting a different health situation.</i>
          <br />
          <br />
          <b>2E. Grateful flow:</b> Penetrate the “black cloud” of thought to harness the sun; the important thing is the process for creating gratefulness and learning to avoid becoming hung up on negative thoughts.
          <br />
          <br />
          <i>Say 3-4 things you’re grateful for – they can be related to your current health or something else in your life; then keep thinking about other things you’re grateful for; then block the flow of thoughts to harness the feeling of gratitude and warmth like the sun on your back.</i>
          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          SECTION 3: GALVANIZING WILL
          </div>
          <b>3A. Three realities of life:</b> Everyone’s life contains pain, uncertainty, and constant work – even the people you idolize; accept that we can’t avoid these realities and embrace them as part of life like the daily rise and fall of the sun; these realities are what cause us to grow and succeed.
          <br />
          <br />
          <i>You may be faced with a heightened sense of pain, uncertainty, and constant work at the moment – it may feel like this is always the case for you; please know that no one is perfect and that a heightened sense of these three realities, with time, provides greater opportunities to build resilience and achieve personal fulfilment; keep growing!</i>
          <br />
          <br />
          If you found this (or other) blogs helpful, we’d love to hear from you at support@joinalpaca.com or on Twitter at @Alpaca_Health. To receive the latest Long COVID and ME/CFS research, our blog posts, and product updates, please <a href="https://www.joinalpaca.com/newsletter">sign-up for our newsletter</a>.
          <br />
          <br/>
          <div>Team Alpaca</div>
          <br />
          <div>Twitter: <a href="https://twitter.com/Alpaca_Health">@Alpaca_Health</a></div>

        </div>
      </div>
    </div>
  );
};

export default Plan;
