import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const status = [
  {
    status: "red",
    statusContent: "Essential Panel",
  },
  {
    status: "green",
    statusContent: "Whole Person Care",
  },
];

const photos = [
  {
    image: "/images/content/alpaca_essential_panel.png",
    image2x: "/images/content/alpaca_essential_panel@2x.png",
    play: false,
  },
  {
    image: "/images/content/essential_actual.png",
    image2x: "/images/content/essential_actual@2x.png",
  },
  {
    image: "/images/content/essential_instructions.png",
    image2x: "/images/content/essential_instructions@2x.png",
    play: false,
  },
  {
    image: "/images/content/essential_support.png",
    image2x: "/images/content/essential_support@2x.png",
    play: false,
  },
];

const items = [
  {
    title: "Biomarkers",
    content: "7",
    icon: "candlesticks",
    size: "18",
  },
  {
    title: "Frequency",
    content: "Every 2 Months",
    icon: "clock",
    size: "16",
  },
  {
    title: "Sample Collection",
    content: "Finger Prick",
    color: "#45B26B",
    icon: "scoreboard",
    size: "20",
  },
  {
    title: "Shipping",
    content: "Free",
    icon: "calendar",
    size: "16",
  },
];

const list = [
  "Track your biological levels, gain peace of mind and insights",
  "Proactively understand your risk factors and take back control",
  "Includes support #, symptoms tracking, cognitive exams",
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Hero = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.details}>
              {status.map((x, index) => (
                <div
                  className={cn(
                    { "status-red": x.status === "red" },
                    { "status-green": x.status === "green" },
                    styles.status
                  )}
                  key={index}
                >
                  {x.statusContent}
                </div>
              ))}
            </div>
            <h2 className={cn("h3", styles.title)}>Alpaca's Essential Panel</h2>
            <div className={styles.info}>
            Track your biological levels and take control. Understand and gain peace of mind with these 7x biomarkers.
            </div>
            <div className={styles.wrap}>
              <Slider className="workout-slider" {...settings}>
                {photos.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={cn("workout-item", styles.item)}>
                      <div className={styles.preview}>
                        <img
                          src={x.image}
                          srcSet={`${x.image2x} 2x`}
                          alt="Workout"
                        />
                        {x.play && (
                          <button className={cn("play", styles.play)}>
                            <Icon name="play" size="21" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.group}>
              {items.map((x, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size={x.size} />
                  </div>
                  <div className={styles.parameter}>{x.title}</div>
                  <div className={styles.content} style={{ color: x.color }}>
                    {x.content}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.text}>
              <p>
              Measure your essential biological levels with this virtually painless at-home test. Results guide our evidence-based integrative care plan and can be shared with your PCP. When you retest, you can see how your levels change over 8 weeks to biologically track your path to relief and long-term health. We cannot ship to NY.
              </p>

            </div>
            <div className={styles.list}>
              {list.map((x, index) => (
                <div className={styles.box} key={index}>
                  {x}
                </div>
              ))}
            </div>
            <div className={styles.btns}>
              <Link className={styles.link} to="/privacy">
                <button className={cn("button-circle-stroke", styles.button)}>
                  <Icon name="lock" size="22" />
                </button>
              </Link>
              <a
                  href="https://buy.stripe.com/eVa6s7b9J1QGd2w5kp"
                  rel="noopener noreferrer"
                >
                <button className={cn("button", styles.button)}>
                  Buy Now
                </button>                
              </a>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
