import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./History.module.sass";

const items = [
  {
    url: "/blog/how-wearables-and-ai-are-reshaping-healthcare",
    title: "How wearables & AI are re-shaping healthcare",
    content:"Feb 13, 2023 | 3 MIN READ",
    author: "Team Alpaca",
    image: "url('/images/content/apple_watch.png')",
    category: "pink",
    cta: "Read more",
    categoryContent: "New"
  },
  {
    url: "/blog/mental-health-learnings-from-jonah-hill-movie-stutz",
    title: "Mental health learnings from Jonah Hill’s movie “Stutz”",
    content:"Feb 09, 2023 | 4 MIN READ",
    author: "Team Alpaca",
    image: "url('/images/content/jonah.jpeg')",
    category: "pink",
    cta: "Read more",
    categoryContent: "New"
  },
  {
    url: "/blog/how-can-pacing-help-to-manage-lc-and-me-cfs-symptoms",
    title: "How can pacing help to manage LC & ME/CFS symptoms?",
    content:"Jan 31, 2023 | 4 MIN READ",
    author: "Team Alpaca",
    image: "url('/images/content/pem.jpeg')",
    category: "pink",
    cta: "Read more",
    categoryContent: "New"
  },
  {
    url: "/blog/how-does-long-covid-and-me-cfs-affect-your-body",
    title: "How does Long COVID & ME/CFS affect your body?",
    content:
      "JANUARY 25, 2023 | 3 MIN READ",
    image: "url('/images/content/long_covid_symptoms.png')",
    cta: "Read more",
    status: "pink",
    statusContent: "new",
  },
  {
    url: "/blog/why-we-chose-to-focus-on-long-covid-and-me-cfs-in-the-us",
    title: "Why we chose to focus on Long COVID and ME/CFS in the US",
    content:
      "DECEMBER 19, 2022 | 3 MIN READ",
    image: "url('/images/content/covid-viral.jpeg')",
    cta: "Our story",
    status: "green",
    statusContent: "Blog #1",
  },
  
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const History = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    adaptiveHeight: true,
  };

  return (
    <div className={styles.history}>
      <div className={styles.wrap}>
        <Slider className="history-slider" {...settings}>
          {items.map((x, index) => (
            <div className={styles.slide} key={index}>
              <div className={cn("history-item", styles.item)}>
                <div
                  className={styles.preview}
                  style={{ backgroundImage: x.image }}
                ></div>
                <div className={styles.details}>
                  <div
                    className={cn(
                      { "status-pink": x.status === "pink" },
                      { "status-green": x.status === "green" },
                      styles.status
                    )}
                  >
                    {x.statusContent}
                  </div>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  <Link
                    to={x.url}
                    className={cn("button-small", styles.button)}
                  >
                    {x.cta}
                  </Link>
                  
                </div>
                <div className={styles.position}>{x.position}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default History;
