import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            About Us
          </div>
          <h1 className={cn("h3", styles.title)}>
          Our mission is to improve access and quality of care for complex chronic illnesses    
          </h1>
          <div className={styles.text}>
          Long COVID sufferers face multi-month waitlists for clinics and siloed specialty care, consistent medical gaslighting, no standard of care, and poor outcomes. Sufferers of ME/CFS and other complex chronic illnesses face a similar story.
          <br /><br/>Our team are Long COVID and ME/CFS sufferers, and clinicians from Mayo Clinic, Mass General, Mt. Sinai and other leading institutions. 
          
          {/*Our clinical advisory board members are medical directors from world-class institutions like Mayo Clinic's Post-COVID-19 Care Clinic, Harvard Medical School, and Massachusetts General Hospital. */}            
            <a style={{ fontWeight: 550 }}>
            {" "}Our goal is to support you on your health journey by improving care access and outcomes over a continuum.
            </a>
            

            <br />
            <br />
          </div>
        </ScrollParallax>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/hugs.jpg"
              srcSetDark="/images/content/hugs.jpg"
              src="/images/content/hugs.jpg"
              srcDark="/images/content/hugs.jpg"
              alt="App"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
