import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "Essential Panel - Inflammation, Vitamins, Hormones, Protein",
        description: "hs-CRP, Ferritin, thyroid function, Vitamin B12, and Vitamin D-25 hydroxy",
      },
      {
        title: "Expanded Panel - Lipids & Metabolic",
        description: "Glucose levels and heart, liver, and kidney function"
      },
      {
        title: "360° Health Baseline",
        description: "Enhances our understanding of your whole-body health over time"
      },
      {
        title: "Evidence-Based Action Plan",
        description: "Curated interventions to support your whole-body health"
      },
      {
        title: "Wearable Sync - 70+ Devices",
        description: "Track real-time health with a range of compatible wearables"
      },
      {
        title: "Personalized Insights",
        description: "Curated interventions specific to your whole-person"
      },
      {
        title: "Intervention & Symptom Tracking (Beta)",
        description: "Real-time log of your actions and health"
      },
      {
        title: "24/7 AI Companion (Beta)",
        description: "Alpaca’s tireless team member that’s always here to help"
      },
      {
        title: "Predictive Care (Beta)",
        description: "Understand your health risks and intervene accordingly"
      },
      {
        title: "Enterprise Dashboard (Beta)",
        description: "Central repository of anonymized usage and outcome data"
      },
    ],
  },
];

const data = [
  {
    title: "Lite Plan",
    description: "2 tests/year",
    color: "#FFD700",
    price: "59",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/28ocQv7XxeDsbYsfZd",
    options: [
      "true",
      "false",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Standard Plan",
    description: "4 tests/year",
    color: "#b9f2ff",
    price: "129",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/aEUaIn4LlfHwbYs28m",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
    ],
  },
  {
    title: "Premium Plan",
    description: "6 tests/year",
    color: "#EF466F",
    price: "199",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/28o4jZ3Hhcvk7IcaER",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
    ],
  },
  {
    title: "Enterprise",
    color: "#010101",
    description: "customized",
    note: "Reach out to team",
    button: "Contact Team",
    link: "mailto:kevin@joinalpaca.com",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
    ],
  },
];

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Alpaca's Blog
        </div>
        <h1 className={cn("h1", styles.title)}>
        How does Long COVID & ME/CFS affect your body?
        </h1>
        <div className={cn("h5", styles.stage)}>
          JANUARY 25, 2023 • 3 MIN
        </div>

        <div className={styles.content}>
          <div className={cn("h5", styles.section)}>
          What is Long COVID and ME/CFS...
          </div>
          <div>
          Post-viral illnesses often affect individuals differently, Long COVID, also known as post-acute sequelae of SARS-CoV-2 infection, and Myalgic Encephalomyelitis/Chronic Fatigue Syndrome (ME/CFS) certainly fit this archetype.
          </div>
          <br />
          Long COVID refers to a range of symptoms that persist for months or years after the acute phase of a COVID-19 infection has resolved. More than 200 symptoms have been identified with impacts on multiple organ systems, including heart, blood vessels, lungs, brain, nervous system, immune system, and gastrointestinal tract. Some of the most common symptoms include, fatigue, brain fog, migraines, dizziness, breathlessness, rapid or irregular heart rhythm, and muscle and joint pain. Long COVID can also cause prolonged emotional and psychological distress, including anxiety and depression.         
          <br />
          <br />
          The medical profession has not yet identified what causes ME/CFS and it is possible that it has more than one cause, including infections, immune system changes, stress, or changes in energy production. The symptomatic presentation of ME/CFS can vary significantly but has a lot of similarities to that of Long COVID listed above. 
          <br />
          <br />
          Some people who have had Long COVID and ME/CFS report experiencing symptoms that can be debilitating and can significantly impact their ability to carry out daily activities, such as work, school, and caring for their families. It can also lead to long-term health issues, such as damage to the heart, blood vessels, brain, nervous system, and lungs. Given it is not yet known why some people develop Long COVID while others do not, or how to prevent it. Research is ongoing to better understand the causes and risk factors of Long COVID, and to develop effective treatments.
          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          Long COVID symptoms and the impact on numerous organs with differing pathology (as displayed in Nature - Long COVID: major findings, mechanisms and recommendations)
          </div>

          <div className={styles.preview}>
            <img
              src="/images/content/long_covid_symptoms.png"
              alt="Long COVID symptoms and the impact on numerous organs with differing pathology"
              />
          </div>

          <div className={cn("h5", styles.section)}>
          How does Long COVID and ME/CFS affect my body…
          </div>
          <div>
          There is still no definitive answer as to how Long COVID and ME/CFS affects our body and organ function but there are a number of hypothesized mechanisms for Long COVID pathogenesis, including immune dysregulation, microbiota disruption, autoimmunity, clotting and endothelial abnormality, and dysfunctional neurological signalling (EBV = Epstein–Barr virus; HHV-6 = human herpesvirus 6).
          </div>

          <br />
          <div className={cn("h5", styles.section)}>
          Hypothesized mechanisms of Long COVID pathogenesis (as displayed in Nature - Long COVID: major findings, mechanisms and recommendations)
          </div>
      
          <div className={styles.previewlg}>
            <img
              src="/images/content/long_covid_hypothesized_mechanisms.png"
              alt="Long COVID symptoms and the impact on numerous organs with differing pathology"
              />
          </div>
          <div>
          The variation in symptomatic presentation across individuals complicates the learning process, but with time, further research (that we will continue to support via our clinical and scientific team and research partners) will help us gain a clearer understanding of how our bodies are responding to Long COVID and ME/CFS, and help us to improve clinical outcomes, faster and at a lower cost for patients, payers, and providers.
          </div>

          <br />
          <div className={cn("h5", styles.section)}>
          How can you manage your symptoms…
          </div>
          <div>
          If you are experiencing symptoms compatible with Long COVID or ME/CFS, it is important to report to an emergency service providers if symptoms are life threatening, or consult with a healthcare professional, to gain a clearer understanding of what may be contributing to your symptoms and the underlying impact on your organ function and whole-person health. Healthcare professionals may be able to provide you with a diagnosis and treatment plan or refer you to a Long COVID clinic or specialist that is specific to your needs.
          </div>
          <br />
          <div>
          • Managing your energy levels and pacing your exertion (physical, mental and emotion) <br />
          • Getting enough sleep and practicing good sleep hygiene <br />
          • Eating a healthy, balanced diet <br />
          • Taking supplements to address any deficiencies in key vitamins and minerals <br />
          • Staying hydrated <br />
          • Practicing stress-management techniques such as deep breathing, meditation, or yoga <br />
          • Engaging in regular physical activity, as tolerated (it is crucial to start slow, such as stretching, and to listen to your body - see point 1 on pacing) <br />
          • Avoiding triggers that may worsen your symptoms, such as certain foods, exertion, or stressful situations <br />
          • Over the counter pain relievers, such as ibuprofen or aspirin may help with headaches and migraines <br />
          • If your migraine is severe and does not respond to over-the-counter medications, your healthcare provider may prescribe a preventative medication or a medication specifically designed to stop a migraine attack in progress <br />
          </div>
          <br />
          <div>
          It's also important to note that recovery from Long COVID and ME/CFS can be a long process and it may take time for your symptoms to improve. It's important to be patient with yourself and to work closely with your healthcare provider to develop a treatment plan that is tailored to your specific needs.
          </div>
          <br/>
          <div>Join the herd!<div>
          <br/>
          <div>Team Alpaca</div>

          <br />
          <br />
          <div>Twitter: <a href="https://twitter.com/Alpaca_Health">@Alpaca_Health</a></div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
