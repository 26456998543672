import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const status = [
  {
    status: "red",
    statusContent: "Expanded Panel",
  },
  {
    status: "green",
    statusContent: "Biomarkers",
  },
];

const photos = [
  {
    image: "/images/content/panels_updated.png",
    image2x: "/images/content/panels_updated.png",
    play: false,
  },
];

const items = [
  {
    title: "Biomarkers",
    content: "7-18x",
    icon: "candlesticks",
    size: "18",
  },
  {
    title: "Testing Frequency",
    content: "2x or 4x per year",
    icon: "clock",
    size: "16",
  },
  {
    title: "Sample Collection",
    content: "Finger Prick",
    color: "#45B26B",
    icon: "scoreboard",
    size: "20",
  },
  {
    title: "Shipping",
    content: "Always Free",
    icon: "calendar",
    size: "16",
  },
];

const list = [
  "2x different panels - Essential and Expanded",
  "At-home CLIA-certified test panels with instructions and free shipping",
  "Simple and virtually painless test experience",
  "Gain insights, scores, and interventions in a week",
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Hero = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.details}>
              {status.map((x, index) => (
                <div
                  className={cn(
                    { "status-red": x.status === "red" },
                    { "status-green": x.status === "green" },
                    styles.status
                  )}
                  key={index}
                >
                  {x.statusContent}
                </div>
              ))}
            </div>
            <h2 className={cn("h3", styles.title)}>Biomarkers & AI Companion</h2>
            <div className={styles.info}>
              <div className={cn("stage", styles.stage)}>
              AI Companion
              </div>
            Our tireless team member works around the clock to track your whole-body health and analyze how interventions are addressing your symptoms and PEM. It digests data on your symptoms, PEM, pacing, sleep, heart rate variability (HRV), exertion, nutrition, and eating patterns to provide a daily pacing score and guide data-driven interventions. <br />
            <br />
            <div className={cn("stage", styles.stage)}>
              Panels
              </div>
              Our at-home multi-organ health panels and AI analytics measure your inflammation, fatigue, stress, immune function, nerve tissue, heart, kidneys, thyroid and liver. Results you can trust — analyzed by CLIA-certified labs and reviewed by US physicians. Read more on our panels below and in our  <a href="/about">FAQs</a>.</div>
            <div className={styles.wrap}>
              <Slider className="workout-slider" {...settings}>
                {photos.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={cn("workout-item", styles.item)}>
                      <div className={styles.preview}>
                        <img
                          src={x.image}
                          srcSet={`${x.image2x} 2x`}
                          alt="Workout"
                        />
                        {x.play && (
                          <button className={cn("play", styles.play)}>
                            <Icon name="play" size="21" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.group}>
              {items.map((x, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size={x.size} />
                  </div>
                  <div className={styles.parameter}>{x.title}</div>
                  <div className={styles.content} style={{ color: x.color }}>
                    {x.content}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.text}> 
              {/*
              <p className={styles.price}>$69 &nbsp; <span className={styles.crossed}>$129</span></p> 
              <div className={styles.note}>
                <strong className={styles.green}>$60 off per month</strong> if you <strong className={styles.black}>start with our platinum</strong> plan. Our plans start at $59 per/mo, chat with our team to learn more.git a
              </div>
              
              */}
              <div className={styles.btns}>
                {/*
                <Link className={styles.link} to="/privacy">
                  <button className={cn("button-circle-stroke", styles.button)}>
                    <Icon name="lock" size="22" />
                  </button>
                </Link>
                */}
                {/*
                <a
                    href="https://buy.stripe.com/3cscQvfpZeDs5A49AH"
                    rel="noopener noreferrer"
                  >
                  <button className={cn("button", styles.button)}>
                    Buy now
                  </button>                
                </a>
                */}
                <Link className={styles.link} to="/waitlist">
                  <button className={cn("button", styles.button)}>
                    Join our waitlist
                  </button>                
                </Link>
                
              </div>
        

            </div>
            <div className={styles.list}>
              <p className={styles.boxtitle}>
                At a glance:
              </p>
              <br />
              {list.map((x, index) => (
                <div className={styles.box} key={index}>
                  {x}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
