import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            RWE Platform for Neuro-Immunology 
          </div>
          <h1 className={cn("h1", styles.title)}>
            AI Platform to Accelerate RWE
          </h1>
          <div className={styles.text}>
            Hyperbeam is an AI platform to accelerate Real-World Evidence (RWE) for neurological conditions. We train our AI algorithms on multi-modal sources {" "}
            <a style={{ fontWeight: 550 }}>
            to advance clinical outcomes and research.  
            </a>
            {" "} 
          </div>
          <div className={styles.btns}>
            <Subscription
              className={styles.subscription}
              placeholder="Learn More &#38; Get Early Access"
            />
          </div>
        </ScrollParallax>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/alpaca-hero.png"
              srcSetDark="/images/content/alpaca-hero.png"
              src="/images/content/alpaca-hero.png"
              srcDark="/images/content/alpaca-hero.png"
              alt="App"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
