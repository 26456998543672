import React from "react";
import styles from "./ExpandedPanel.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";
import FAQ from "./Faq";
import Book from "./Book";
import ValueProps from "../../components/ValuePropsPanel";
import RegularTest from "./RegularTest";
import Offer from "../../components/Offer";
import EssentialBiomarkers from "../../components/EssentialBiomarkers"

const EssentialPanel = () => {
  return (
    <>
      <Hero />
      <ValueProps />
      <Book />
      <EssentialBiomarkers 
        classNameSection="section-pb"
        classNameTitle="h2"
        title="Biomarkers We Test For"
      />
      <RegularTest />
      <FAQ />
    </>
  );
};

export default EssentialPanel;
