import React from "react";
import styles from "./Pricing.module.sass";
import Plan from "./Plan";
import Comment from "./Comment";
import Faq from "./Faq";
import Interesting from "./Interesting";

import Testimonials from "../../components/Testimonials";

const Plans = () => {
  return (
    <>
      <Plan />
      <Interesting />
    </>
  );
};

export default Plans;
