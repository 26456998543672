import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "Resource Catalog",
        description: "Access to our newsletter, blog, curated interventions, and the latest research",
      },
      {
        title: "360° Health Quiz",
        description: "Diagnostic questionnaire to baseline and monitor your whole-body health"
      },
      {
        title: "AI Companion",
        description: "Tracks your health data to analyze how interventions are addressing your symptoms and PEM",
      },
      {
        title: "Analytics Tracker",
        description: "Health dashboard to gain insights on your symptoms, pacing, wearables, and biomarker data",
      },
      {
        title: "Join Research",
        description: "Share your data with the click of a button to participate in the latest research studies",
      },
      {
        title: "Pacing Partner",
        description: "AI monitoring, analysis, and coaching to manage your pacing schedule and help to minimize PEM"
      },
      {
        title: "Wearables Sync",
        description: "Integrate real-time health data capture from a range of compatible wearables to enhance analytics and support"
      },
      {
        title: "Essential Panel",
        description: "At-home blood test of 7 key biomarkers curated for Long COVID"
      },
      {
        title: "Expanded Panel",
        description: "At-home blood test of 18 key biomarkers curated for Long COVID and multi-organ health"
      },
      {
        title: "Personalized Intervention Plan",
        description: "Evidence-based interventions curated from your health baseline, symptoms, wearables, and biomarker data"
      },
      {
        title: "Prognostic AI Care",
        description: "AI analysis and prediction of your healthspan from baseline, symptoms, wearables, intervention, and biomarker data"
      },
    ],
  },
];

const data = [
  {
    title: "Free",
    description: "Panels by order",
    color: "#f1c40f",
    price: "0",
    note: "per month",
    button: "Join our waitlist",
    link: "/waitlist",
    options: [
      "true",
      "true",                 
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Lite",
    description: "Panel discounts",
    color: "#e67e22",
    price: "$19",
    note: "per month",
    button: "Join our waitlist",
    link: "/waitlist",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Standard",
    description: "2x panels / yr",
    color: "#3498db",
    price: "$59",
    note: "per month",
    button: "Join our waitlist",
    link: "/waitlist",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "true",
      "false",
    ],
  },
  {
    title: "Premium",
    description: "4x panels / yr",
    color: "#34495e",
    price: "$129",
    note: "per month",
    button: "Join our waitlist",
    link: "/waitlist",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
    ],
  },
];

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Track levels. Take Control.
        </div>
        <h1 className={cn("h1", styles.title)}>
        Pre-order today for exclusive savings
        </h1>
        <div className={cn("h5", styles.stage)}>
        <strong className={styles.green}>50% lifetime discount</strong> if you join the waitlist today
        </div>
        <br />
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.preordertop}>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>
                        <div className={styles.hint}>
                          <Icon name="info" size="10" />
                          <div className={styles.tooltip}>
                            {item.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              </div>
            </div>
            {data.map((type, index) => (
              <div className={styles.col} key={index}>
                <div>
                  <div className={styles.package} style={{ color: type.color }}>
                    {type.title}
                  </div>
                  <div className={styles.description}>{type.description}</div>
                   {/*
                  <div className={styles.description}>{type.description}</div>
                  {type.price && (
                    <>
                      <div className={styles.cost}>
                        <span className={styles.sign}>$</span>{" "}
                        <span className={styles.price}>{type.price}</span>
                      </div>
                      <div className={styles.note}>{type.note}</div>
                    </>
                  )}*/}
                  
                </div>
                <div className={styles.body}>
                  <div
                    className={cn(styles.more, {
                      [styles.active]: more[index],
                    })}
                    onClick={() => handleClick(index)}
                  >
                    See all features
                    <Icon name="arrow-bottom" size="9" />
                  </div>
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  >
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  

                  <a
                    className={styles.link}
                    activeClassName={styles.active}
                    href={type.link}
                  >
                        <button
                          className={cn(
                            { button: index === 0 },
                            { button: index === 1 },
                            { button: index === 2 },
                            { button: index === 3 },
                            styles.button
                          )}
                        >
                          {type.button}
                      </button>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
