import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import ScrollParallax from "../ScrollParallax";

const Card = ({ className, item }) => {
  return (
    <ScrollParallax className={cn(styles.card, className)}>
      <Link className={styles.link} to={item.url}>
        <div className={styles.preview}>
          <img srcSet={`${item.image2x} 2x`} src={item.image} alt="Card pic" />
          <div
            className={cn(
              { "status-green": item.category === "green" },
              { "status-blue": item.category === "blue" },
              { "status-red": item.category === "red" },
              styles.category
            )}
          >
            {item.categoryText}
          </div>
          {item.play && (
            <button className={cn("play-small", styles.play)}>
              <Icon name="play" size="14" />
            </button>
          )}
        </div>
        
      </Link>
    </ScrollParallax>
  );
};

export default Card;
