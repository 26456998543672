import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "Essential Panel",
    items: [
      {
        title: "Can I use my HSA or FSA to pay for Alpaca Health's Test Kit?",
        answer: `In most cases, you can use your HSA/FSA account to pay for your 7-in-1 at-home immune, brain, and thyroid test.\n\nYour Alpaca Health test is authorized by a physician, so it should fit the guidelines for most HSA/FSA coverage. Your Alpaca Health test kit purchase can be submitted for reimbursement through HSA/FSA benefits\n\nAs always, be sure to check with your specific HSA/FSA benefits coordinator for clarification on the specific requirements for reimbursement including usage, protocol, and qualifications.
        `
      },
      {
        title: "How accurate are the results from Alpaca Health?",
        answer: "Very! Every Alpaca Health test is ordered by a doctor. Samples are processed in CLIA-approved and CAP-accredited labs, which are the highest levels of pathology accreditation. Leading care providers and hospitals use the same labs. A physician will review your results before they are returned."
      },
      {
        title: "What biomarkers are measured on your Essential Test Kit?",
        answer: `COVID-19 can attack the body in a range of ways, causing damage to the lungs, heart, nervous system, kidneys, liver and other organs. We have curated 7 biomarkers for the essential panel to better understand the impact on your health:
        \n• Alpaca's Essential Blood Test Kit (1-7): hs-CRP, ferritin, vitamin B12, vitamin D25-hydroxy, T3, FT4, and TSH 
        \nLong COVID is not just afflicting people who were severely unwell with acute COVID-19. Many patients who experienced only mild symptoms with acute COVID-19 have been struggling with long-term symptoms.
        `
      },
      {
        title: "How do I know if the Alpaca Health test is right for me?",
        answer: `The Alpaca Health at-home panel is for both patients who are Long COVID sufferers and those facing hypothyroidism, immune dysfunction, and/or cognitive impairment. In all cases, knowing your inflammation and hormone levels and making sure that they are within range is very important.`
      },
      {
        title: "How do these biomarkers affect me?",
        answer: `Alpaca's Essential Blood Test Kit (1-7):
        \n1. hs-CRP (high-sensitivity C-Reactive Protein): Protein produced by the liver in response to inflammation in your body. It may be elevated due to certain chronic illnesses, medications, or recent illnesses. hs-CRP is often used as part of a broader, comprehensive assessment evaluating heart health, chronic inflammatory diseases, and characteristically elevated in inflammatory thyroid disorders.
        \n2. Ferritin: This iron storage protein is key to resolving thyroid hair-loss, fatigue, restless leg, cold intolerance, and feeling unwell despite thyroid meds. Ferritin levels are also key to understanding how efficiently oxygen is getting delivered to all areas of your body. If ferritin is lower than normal, it indicates an iron deficiency, which could result in anaemia and symptoms including, dizziness, headaches, shortness of breath, and rapid heartbeat
        \n3. Vitamin B12: Vitamin that affects your overall energy, concentration, focus and memory. This is  commonly low and can lead to low energy, brain fog, and tingling arms, and legs. Also known as Cobalamin, Vitamin B12 is important for the normal formation of red blood cells and the health of nerve tissues. Neurological features of Long COVID, Hashimoto's, and Vitamin B12 deficiency overlap.
        \n4. Vitamin D25-Hydroxy: Vitamin D has many important roles in the body, including bone formation and maintenance as well as immune function, among others. Epidemiological research has identified vitamin D as a risk factor for more severe COVID-19. Managing Vitamin D levels can help with depression, antibodies and pain.
        \n5. T3 (Triiodothyronine): Thyroid hormone that affects almost every physiological process in your body, including growth and development, metabolism, body temperature, and heart rate. Levels of T3 fluctuate when individuals have an under or overactive thyroid gland.
        \n6. FT4 (Free Thyroxine): Free T4 is the predominant hormone produced by the thyroid gland. Levels fluctuate when individuals have an under or overactive thyroid gland. Together, with T3, these hormones regulate a number of your body's functions, including temperature, metabolism, heart rate, brain development, nervous system, cholesterol, breathing, etc.
        \n7. TSH (Thyroid-Stimulating Hormone): Also known as thyrotropin or thyrotropic hormone, is a pituitary hormone that stimulates your thyroid gland to produce T4 and T3. The hormone TSH is considered the most sensitive marker for screening for thyroid diseases and conditions.`
      },
      {
        title: "How long is the panel testing process?",
        answer: "The entire test panel process should take less than 20 minutes and should take 5-7 business days for results."
      },
      {
        title: "Where is my lab work done?",
        answer: "Your samples are analyzed at a high-complexity laboratory that is CLIA and CAP accredited, located in San Diego, CA. CLIA Number: 05D2130115 and CAP Number: 8089263."
      },
      {
        title: "How do I take my at-home blood test?",
        answer: `Testing is a straightforward process - it requires a bit of preparation, a quick blood sample, and little to no pain. For best results, we recommend taking the test in the morning before breakfast after fasting for 10-12 hours. Then follow, the below instructions:
        \n1. Consume 2 glasses of water 30 minutes before testing - hydration helps blood flow.
        \n2. Complete your information card & register - ‍fill out the collection card with your name, today’s date, and your date of birth. Then, scan the QR code on the front of the card or visit tests.joinalpaca.com/register
        \n3. Get your blood flowing - warm your hands with hot water or rub them together for a few minutes, then shake your hands below your waist.
        \n4. Choose a finger - wipe the tip of your middle or ring finger with the alcohol wipe.
        \n5. Prick your finger - twist off the lancet cap (you will not see the needle). Press the lancet into the side of your finger to puncture the skin. Allow the first drop of blood to drip onto the provided gauze.
        \n6. Collect your blood - on just one small square, drip 2-4 drops of blood. If needed, gently squeeze your finger downward to help release more blood. Blood will flow to the right of the card. Do not touch the card and set it aside to dry completely.
        \n7. Apply a Bandaid - it will help to keep your finger clean.
        \n8. Pack up sample & return - once the sample appears dry to the touch, put the card into the foil bag and the bag into the return envelope, then mail. Once our lab receives the sample, it will be tested and you will be notified of your results. Results are typically made available within two - five business days after receiving your sample back.`
      },
      {
        title: "What should my blood spot sample look like?",
        answer: `• Your name and date should be on the card
        \n• Your blood sample should fill the collection square using drops of blood
        \n• Your blood sample should spread to the line indicated on the card
        \n• Your blood sample should NOT be smeared on the outside of the card or elsewhere on the square`,
        image: "/images/content/blood_spot_example.png",
        info: "Please refer to the picture"
      },
      {
        title: "What if I can't get enough blood?",
        answer: `Blood must be taken in one sitting. You cannot collect some and then come back later to collect more, this will result in a failed test.
        \nTry to warm your hands with hot water or rub them together for a few minutes, then shake your hands below your waist.
        \nIf you find it too difficult to draw your blood, one of our team members will gladly schedule a video call with you to answer questions and give support.`
      },
      {
        title: "How do I know if the Alpaca Health test is right for me?",
        answer: "We currently serve all US states, excluding New York. New York State’s Department of Health requires every biomarker tested to be separately validated and approved by the state—this process typically takes 12-18 months. Given the novel nature of our curated biomarker panels, we do not yet have the clearances required to provide them to New York state residents but hope to do so in the future."
      },
      {
        title: "Where does Alpaca deliver to?",
        answer: "We currently serve all US states, excluding New York. New York State’s Department of Health requires every biomarker tested to be separately validated and approved by the state—this process typically takes 12-18 months. Given the novel nature of our curated biomarker panels, we do not yet have the clearances required to provide them to New York state residents but hope to do so in the future."
      },
      {
        title: "How do changes to my plan work?",
        answer: "We’re always here to help you, even if your life changes! Change plans anytime at least 48 hours before your test is shipped at no extra cost. It’s that simple!",
      },
      {
        title: "How do cancellations and refund work?",
        answer: "We hope you never fall out of love with us but we definitely don’t want you to stress about our relationship! Cancel anytime at least 48 hours before your test is shipped for a full refund. No strings attached!",
      },
    ]
  },
];



const Team = () => {
  const options = [];
  items.map(x => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn("stage-small", styles.stage)}>
            Frequently Asked Questions (FAQs)
          </div>
          <h2 className={cn("h2", styles.title)}>Common Questions</h2>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find(x => x.title === category)
            .items.map((x, index) => (
              <Item item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
