import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "Essential Panel - Inflammation, Vitamins, Hormones, Protein",
        description: "hs-CRP, Ferritin, thyroid function, Vitamin B12, and Vitamin D-25 hydroxy",
      },
      {
        title: "Expanded Panel - Lipids & Metabolic",
        description: "Glucose levels and heart, liver, and kidney function"
      },
      {
        title: "360° Health Baseline",
        description: "Enhances our understanding of your whole-body health over time"
      },
      {
        title: "Evidence-Based Action Plan",
        description: "Curated interventions to support your whole-body health"
      },
      {
        title: "Wearable Sync - 70+ Devices",
        description: "Track real-time health with a range of compatible wearables"
      },
      {
        title: "Personalized Insights",
        description: "Curated interventions specific to your whole-person"
      },
      {
        title: "Intervention & Symptom Tracking (Beta)",
        description: "Real-time log of your actions and health"
      },
      {
        title: "24/7 AI Companion (Beta)",
        description: "Alpaca’s tireless team member that’s always here to help"
      },
      {
        title: "Predictive Care (Beta)",
        description: "Understand your health risks and intervene accordingly"
      },
      {
        title: "Enterprise Dashboard (Beta)",
        description: "Central repository of anonymized usage and outcome data"
      },
    ],
  },
];

const data = [
  {
    title: "Lite Plan",
    description: "2 tests/year",
    color: "#FFD700",
    price: "59",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/28ocQv7XxeDsbYsfZd",
    options: [
      "true",
      "false",
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
  {
    title: "Standard Plan",
    description: "4 tests/year",
    color: "#b9f2ff",
    price: "129",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/aEUaIn4LlfHwbYs28m",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
      "false",
    ],
  },
  {
    title: "Premium Plan",
    description: "6 tests/year",
    color: "#EF466F",
    price: "199",
    note: "per month",
    button: "Order Now",
    link: "https://buy.stripe.com/28o4jZ3Hhcvk7IcaER",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "false",
    ],
  },
  {
    title: "Enterprise",
    color: "#010101",
    description: "customized",
    note: "Reach out to team",
    button: "Contact Team",
    link: "mailto:kevin@joinalpaca.com",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
    ],
  },
];

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Alpaca's Blog
        </div>
        <h1 className={cn("h1", styles.title)}>
        Why we chose to focus on Long COVID and ME/CFS in the US
        </h1>
        <div className={cn("h5", styles.stage)}>
          DECEMBER 19, 2022 • 3 MIN
        </div>
        <div className={cn("h5", styles.stage)}>
          
        </div>
        <div className={styles.preview}>
            <img
              src="/images/content/covid-viral.jpeg"
              alt="Professor or Doctor picture"
              />
        </div>
        <div className={styles.content}>
          <div className={cn("h5", styles.section)}>
          The dilemma facing Alpaca Health...
          </div>
          <div>
          We're committed to improving human healthspan (number of healthy years) at scale, globally. But as an early-stage health assurance company, there is a disconnect between our long-term vision and our current resources. We're faced with the age-old dilemma - where do we start?
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          Long COVID and ME/CFS in the US is a burning problem…          
          </div>
          <div>
          Despite the great leaps in healthcare made over the past century, millions of folks around the world are struggling with complex health issues impacting their life expectancy and healthspan. In the US, life expectancy has risen fairly consistently from 47 years in 1900 to 79 years in 2019 (excluding the devastating impact of WWI). However, between 2019 and 2021, life expectancy in the US fell by 3 years down to 76 years (1). These figures don't highlight the significant variances across population cohorts or the fact that the US spends the most on healthcare globally (as a % of GDP, per capita, and in aggregate) yet sits well into the bottom half of OECD countries based on life expectancy (2). Although less widely tracked and reported, average healthspan in the US has been worse off with a  fall in recent years similar to life expectancy but without a similar improvement trajectory witnessed over the twentieth century (3). 
          </div>
          <br />
          <div>
          Numerous studies around the world, including from Johns Hopkins Medical School, have shown that Long COVID can affect every organ system in the body, with some sufferers having persistent lung, heart, nervous system, kidneys, liver, and mental health issues post-infection (4). Further, Long COVID and other post-viral illnesses commonly exacerbate pre-existing conditions, such as heart disease, respiratory disease, cancer, and diabetes. However, post-COVID illness is not just afflicting people with prior conditions or those who suffered severe acute COVID. The CDC estimates that Long COVID has affected ~30M Americans and that it is more prevalent among women, the LGBTQI+ community, and those who identify as Hispanic or Mixed Race (5). There is also increasing evidence that those who suffer multiple COVID infections are more likely to develop long-term post-COVID complications. In fact, a study published by Nature Medicine, indicated that those who have suffered multiple infections were 3.5 times more likely to develop lung problems, 3 times more likely to have heart conditions, and 1.6 times more likely to have brain changes requiring care than people who had only had COVID once (6).
          </div>
          <br />
          <div>
          Of the folks who have tested positive for acute COVID, younger cohorts aged 18-59 years are more likely to be affected by Long COVID compared to those aged 60+ years (5). This is taking a heavy toll on the productivity of many who are in their prime working years, so much so that a Harvard Economics Professor estimates that Long COVID constitutes a $3.7T drag on the US economy (7). While Long COVID is a new challenge arising in the past couple years, the CDC estimates that a further 800K to 2.5M Americans suffer from an equally vexing illness many consider as a close cousin - ME/CFS or Myalgic Encephalomyelitis/Chronic Fatigue Syndrome (8). Unfortunately, for these sufferers and those with Long COVID looking for answers, many folks with ME/CFS have been battling debilitating symptoms without a great deal of relief for many years and even decades. 
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          Our team has struggled with Long COVID...       
          </div>
          <div>
          Despite previously being young and healthy, our team members have been struggling with Long COVID for most of 2022, including having to take multiple months off from work to focus on our recovery. One of our co-founders was hospitalized in New York with COVID complications, the other has visited his PCP 10 times, has given 24 vials of blood, faced 2-4 month waitlists to see multiple specialists, and has been on a 10-month waitlist for a Long COVID clinic. Our team has also previously battled ME/CFS after a prolonged period of post-viral fatigue following Glandular Fever, which resulted in multiple months off of work to begin the recovery process.
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          What can Alpaca Health do about it…          
          </div>
          <div>
          Unfortunately, the current healthcare system is not well suited to manage this large influx of complex chronic patients requiring inter-disciplinary care. The existing system for specialty care often requires 2-4 month waiting periods to receive episodic and siloed care that often struggles to drive meaningful outcomes among cohorts with these not-well-understood conditions. Given the novelty and scale of Long COVID and ME/CFS, we need to build the infrastructure to provide a scalable continuum of interdisciplinary care that can generate and analyze extensive datasets on symptoms, critical organ function, interventions, and outcomes - this is where we believe Alpaca can play a role. From our own experience, and that of other Long COVID and ME/CFS sufferers, the primary complaint is trying to avoid debilitating post-exertional malaise. We want to tackle this head on by providing accessible biomarker testing to better understand organ function, evidence-based interventions, and ongoing support with adherence, pacing, and symptom tracking. 
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          How can you help…          
          </div>
          <div>
          If you'd like to share your story, gain support from our services, or suggest other condition(s) that could benefit from further research and support around managing symptoms and pacing, please send the team a message at support@joinalpaca.com
          </div>
          <br />
          <div>Wishing you health and happiness!</div>
          <br />
          <div>Team Alpaca</div>
          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          References        
          </div>
          <div>
          (1) CDC Provisional Life Expectancy Estimates for 2021: https://www.cdc.gov/nchs/data/vsrr/vsrr023.pdf <br/>
          (2) OECD Health Spending: https://data.oecd.org/healthres/health-spending.htm <br/>
          (3) Longevity Leap: mind the healthspan gap (Nature): https://www.nature.com/articles/s41536-021-00169-5 <br/>
          (4) Long COVID: Long-Term Effects of COVID-19: https://www.hopkinsmedicine.org/health/conditions-and-diseases/coronavirus/covid-long-haulers-long-term-effects-of-covid19 <br/>
          (5) CDC Long COVID - Household Pulse Survey: https://www.cdc.gov/nchs/covid19/pulse/long-covid.htm<br/>
          (6) Acute and postacute sequelae associated with SARS-CoV-2 reinfection: https://www.nature.com/articles/s41591-022-02051-3<br/>
          (7) The Economic Cost of Long COVID (An Update): https://scholar.harvard.edu/files/cutler/files/long_covid_update_7-22.pdf<br/>
          (8) What is ME/CFS: https://www.cdc.gov/me-cfs/about/index.html#:~:text=An estimated 836%2C000 to 2.5,CFS have not been diagnosed.<br/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
