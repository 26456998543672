import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";
import { Popup } from 'react-typeform-embed';

const menu = [
  {
    title: "About Us",
    url: "/about"
  },
  {
    title: "Our Blog",
    url: "/blog"
  },
  {
    title: "Privacy",
    url: "/privacy"
  },
  {
    title: "Terms",
    url: "/terms"
  },
];

const socials = [
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/Alpaca_Health"
  },
  {
    title: "instagram",
    size: "18",
    url: "https://www.instagram.com/alpacahealth/"
  },
  {
    title: "calendar",
    size: "18",
    url: "mailto: support@joinalpaca.com"
  }
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                  <Image
                  src="/images/logo.svg"
                  srcDark="/images/logo.svg"
                  alt="Alpaca Health"
                  />
              </Link>
            <Theme className={styles.theme} />
            </div>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                Menu
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => 
                  (
                    <Link
                      className={styles.link}
                      activeClassName={styles.active}
                      to={{
                        pathname: x.url
                      }}
                      key={index}
                    >
                      {x.title}
                    </Link>
                  ) 
                  
                )}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>contact</div>
            <div className={styles.info}>
              <p>Crafted with coffee</p>
              <p>New York, NY 🗽</p>
              <br />
              <p>support@joinalpaca.com</p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Connect with us</div>
            <div className={styles.info}>
            Connect with our team to learn more about our AI solutions            
            </div>
            <div className={styles.btns}>
              <Link
                className={styles.link}
                activeClassName={styles.active}
                to={{
                  pathname: "/waitlist" 
                }}
              >
                <button
                    className={cn("button", styles.button)}
                  >
                    <span>Join our waitlist</span>
                    <Icon name="arrow-right" size="10" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2023 Hyperbeam AI (dba Alpaca Health). All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
