import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";
import { Popup } from 'react-typeform-embed';
import Icon from "../Icon";
import Slider from "react-slick";
import Card from "../Card";

const items = [
  {
    title: "Alpaca's Essential Blood Test Panel",
    url: "/at-home-quarterly-7-in-1-plan",
    image: "/images/content/essential_panel.png",
    image2x: "/images/content/essential_panel.png",
    category: "blue",
    categoryText: "7-in-1 Essential At-Home Blood Test Kit ",
    avatar: "/images/content/avatar-1.png",
    trainer: "Alpaca's Essential Blood Test Panel",
    level: "blue",
    levelText: "Essential",
    counterVideo: "7",
    counterUser: "160",
  }
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Offer = ({ className }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>Join Alpaca today</div>
        <h2 className={cn("h1", styles.title)}>
          Track levels. <br />
          Take control.
        </h2>
        <div className={styles.text}>
        <div>
        Take charge of your health today. Join our Free AI Companion Pilot to manage your symptoms, pacing, and PEM.
        </div>
        </div>
        <div className={styles.btns}>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            to={{
              pathname: "/waitlist" 
            }}
          >
            <button
                className={cn("button", styles.button)}
              >
                <span>Join our waitlist</span>
                <Icon name="arrow-right" size="10" />
            </button>
          </Link>
        </div>
        <br />
        <br />
        <div className={styles.gallery}>
            <video className={styles.video} loop autoPlay muted playsInline poster={"/content/mobile_background.png"}>
                <source
                    src="/videos/background_video.mp4" 
                    type="video/mp4"
                />
          </video>
        </div>
        {/*
        <div className={styles.wrap}>
            <Slider className="interesting-slider" {...settings}>
              {items.map((x, index) => (
                <div className={styles.slide} key={index}>
                  <Card className={styles.card} item={x} />
                </div>
              ))}
            </Slider>
        </div>
        {/*
        <div className={styles.bluebtns}>
              <a
                  href="https://buy.stripe.com/7sI5o391B7b08Mg28p"
                  rel="noopener noreferrer"
                >
                <button className={cn("button", styles.button)}>
                  Order now
                </button>                
              </a>
        </div>
              */}
      </div>
    </div>
  );
};

export default Offer;
