import React from "react";
import styles from "./Pricing.module.sass";
import Plan from "./Plan";
import Comment from "./Comment";
import Faq from "./Faq";
import Testimonials from "../../components/Testimonials";

const FAQ = () => {
  return (
    <>
      <Faq />
    </>
  );
};

export default FAQ;
