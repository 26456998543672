import React, { useRef } from "react";
// import styles from "./Home.module.sass";
import Hero from "./Hero";
import TeamLogo from "../../components/TeamLogo";
import Steps from "./Steps";
import Intro from "../../components/Intro";
import Book from "./Book";
import ValueProps from "../../components/ValueProps";
import Team from "./Team";
import Review from "../../components/Review";
import Quality from "./Quality";
import Advantages from "../../components/Advantages";
import Workouts from "../../components/Workouts";
import Offer from "../../components/Offer";
import Plan from "./Plan";
import { Popup } from 'react-typeform-embed';
import Testimonials from "../../components/Testimonials";
import FAQ from "../FAQ/Faq";

const About = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero />
      {/*<ValueProps/>*/}
      {/*<Plan className="section-border-top"/>*/}
      <Offer className="section-border-top" />
      {/*<Popup id="NZBTYjEr" width={500} height={600} disableAutoFocus/>*/}
    </>
  );
};

export default About;
