import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Heart from "./screens/Heart";
import Hyperbeam from "./screens/Hyperbeam";
import LongCOVID from "./screens/LongCOVID";
import Inflammation from "./screens/Inflammation";
import Features from "./screens/Features";
import Plans from "./screens/Plans";
import Download from "./screens/Download";
import Preorder from "./screens/Preorder";
import Thanks from "./screens/Thanks";
import Class01 from "./screens/Class01";
import ExpandedPanel from "./screens/ExpandedPanel";
import Class02 from "./screens/Class02";
import Class02Details from "./screens/Class02Details";
import Blog from "./screens/Blog";
import Article1 from "./screens/Blog/Article1";
import Blog2 from "./screens/Blog/Blog2";
import Blog3 from "./screens/Blog/Blog3";
import Blog4 from "./screens/Blog/Blog4";
import Blog5 from "./screens/Blog/Blog5";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import EssentialPanel from "./screens/EssentialPanel";
import InflammationThyroid from "./screens/InflammationThyroid";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
import OnboardingQuiz from "./screens/OnboardingQuiz";
import About from "./screens/About";
import Quiz360 from "./screens/360Quiz";
import BCGQuiz from "./screens/BCGQuiz";
import FAQ from "./screens/FAQ";
import Newsletter from "./screens/Newsletter"
import Waitlist from "./screens/Waitlist"

const trackingCode = "UA-222878894-1";

function App() {
  ReactGA.initialize(trackingCode);
  ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    hotjar.initialize(2875151, 6);
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <LongCOVID />
            </Page>
          )}
        />
        <Route
          exact
          path="/heart"
          render={() => (
            <Page>
              <Heart />
            </Page>
          )}
        />
                <Route
          exact
          path="/hyperbeam"
          render={() => (
            <Page>
              <Hyperbeam />
            </Page>
          )}
        />
        <Route
          exact
          path="/about"
          render={() => (
            <Page>
              <About />
            </Page>
          )}
        />
        <Route
          exact
          path="/long-covid"
          render={() => (
            <Page>
              <LongCOVID />
            </Page>
          )}
        />
        <Route
          exact
          path="/inflammation"
          render={() => (
            <Page>
              <Inflammation />
            </Page>
          )}
        />
        <Route
          exact
          path="/features"
          render={() => (
            <Page>
              <Features />
            </Page>
          )}
        />
        <Route
          exact
          path="/plans"
          render={() => (
            <Page>
              <Preorder />
            </Page>
          )}
        />
        <Route
          exact
          path="/download"
          render={() => (
            <Page>
              <Download />
            </Page>
          )}
        />
        <Route
          exact
          path="/class01"
          render={() => (
            <Page>
              <Class01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/whats-measured"
          render={() => (
            <Page>
              <ExpandedPanel />
            </Page>
          )}
        />
        <Route
          exact
          path="/essential-panel"
          render={() => (
            <Page>
              <EssentialPanel />
            </Page>
          )}
        />
        <Route
          exact
          path="/at-home-quarterly-7-in-1-plan"
          render={() => (
            <Page>
              <InflammationThyroid />
            </Page>
          )}
        />
        <Route
          exact
          path="/class02"
          render={() => (
            <Page>
              <Class02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/privacy"
          render={() => (
            <Page>
              <Privacy />
            </Page>
          )}
        />
        <Route
          exact
          path="/onboarding-quiz"
          render={() => (
            <Page>
              <OnboardingQuiz />
            </Page>
          )}
        />
                <Route
          exact
          path="/faq"
          render={() => (
            <Page>
              <FAQ />
            </Page>
          )}
        />
        <Route
          exact
          path="/360-quiz"
          render={() => (
            <Page>
              <Quiz360 />
            </Page>
          )}
        />
        <Route
          exact
          path="/terms"
          render={() => (
            <Page>
              <Terms />
            </Page>
          )}
        />
        <Route
          exact
          path="/class02-details"
          render={() => (
            <Page>
              <Class02Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/blog"
          render={() => (
            <Page>
              <Blog />
            </Page>
          )}
        />
        <Route
          exact
          path="/blog/why-we-chose-to-focus-on-long-covid-and-me-cfs-in-the-us"
          render={() => (
            <Page>
              <Article1 />
            </Page>
          )}
        />
        <Route
          exact
          path="/blog/how-does-long-covid-and-me-cfs-affect-your-body"
          render={() => (
            <Page>
              <Blog2 />
            </Page>
          )}
        />
         <Route
          exact
          path="/blog/how-can-pacing-help-to-manage-lc-and-me-cfs-symptoms"
          render={() => (
            <Page>
              <Blog3 />
            </Page>
          )}
        />
        <Route
          exact
          path="/blog/mental-health-learnings-from-jonah-hill-movie-stutz"
          render={() => (
            <Page>
              <Blog4 />
            </Page>
          )}
        />
        <Route
          exact
          path="/blog/how-wearables-and-ai-are-reshaping-healthcare"
          render={() => (
            <Page>
              <Blog5 />
            </Page>
          )}
        />
         <Route
          exact
          path="/Class02Details"
          render={() => (
            <Page>
              <Class02Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/newsletter"
          render={() => (
            <Page>
              <Newsletter />
            </Page>
          )}
        />
        <Route
          exact
          path="/waitlist"
          render={() => (
            <Page>
              <Waitlist />
            </Page>
          )}
        />
        <Route
          exact
          path="/thanks"
          render={() => (
            <Page>
              <Thanks />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
