import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import Typing from 'react-typing-animation';

const status = [
  {
    status: "grey",
    statusContent: "Immune | Brain | Thyroid",
  },
];

const photos = [
  {
    image: "/images/content/essential_panel.png",
    image2x: "/images/content/essential_panel.png",
    play: false,
  },
  {
    image: "/images/content/alpaca_actual.png",
    image2x: "/images/content/alpaca_actual.png",
    play: false,
  },
  {
    image: "/images/content/instructions.png",
    image2x: "/images/content/instructions.png",
    play: false,
  },
  {
    image: "/images/content/hsa.png",
    image2x: "/images/content/hsa.png",
    play: false,
  },
];

const items = [
  {
    title: "Biomarkers",
    content: "7",
    icon: "candlesticks",
    size: "18",
  },
  {
    title: "Frequency",
    content: "Every 2 Months",
    icon: "clock",
    size: "16",
  },
  {
    title: "Sample Collection",
    content: "Finger Prick",
    color: "#45B26B",
    icon: "scoreboard",
    size: "20",
  },
  {
    title: "Shipping",
    content: "Free",
    icon: "calendar",
    size: "16",
  },
];

const biomarkers = [
  {
    "title": "hs-CRP"
  },
  {
    "title": "Ferritin"
  },
  {
    "title": "Vitamin B12"
  },
  {
    "title": "Vitamin D"
  },
  {
    "title": "T3"
  },
  {
    "title": "FT4"
  },
  {
    "title": "TSH"
  },
]

const list = [
  "Track your biological levels, gain peace of mind and insights",
  "Proactively understand your risk factors and take back control",
  "Includes support #, symptoms tracking, cognitive exams",
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);


const Hero = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll:  1,
    dots: true,
    adaptiveHeight: true,
    autoplay: false,
    cssEase: "linear",
    lazyLoad: true,
    adaptiveHeight: true,
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.wrap}>
              <Slider className="workout-slider" {...settings}>
                {photos.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={cn("workout-item", styles.item)}>
                      <div className={styles.preview}>
                        <img
                          src={x.image}
                          srcSet={`${x.image2x} 2x`}
                          alt="Workout"
                        />
                        {x.play && (
                          <button className={cn("play", styles.play)}>
                            <Icon name="play" size="21" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className={styles.col}>
          {}
          <div className={styles.details}>
              {status.map((x, index) => (
                <div
                  className={cn(
                    { "status-blue": x.status === "blue" },
                    { "status-green": x.status === "green" },
                    { "status-grey": x.status === "grey" },
                    styles.status
                  )}
                  key={index}
                >
                  {x.statusContent}
                </div>
              ))}
          </div>
          <h4 className={cn("h4", styles.title)}>Quarterly 7-in-1 Plan</h4>
            <div className={styles.info}>
            7-in-1 blood test done quarterly from your home with our 360° quiz and evidence-based interventions to drive outcomes. Gain insight on your{" "}
              <span>
                <Typing loop="True" className={styles.typing}>
                  <Typing.Speed ms={50} />
                  <b>exhaustion</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Speed ms={100} />
                  <Typing.Backspace count={10} />
                  <b>fatigue</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={7} />
                  <b>brain fog</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={9} />
                  <b>muscle aches</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={12} />
                  <b>immune function</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={15} />
                  <b>shortness of breath</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={19} />
                  <b>focus and memory</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={17} />
                  <b>weight gain</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={11} />
                  <b>constipation</b>
                  <Typing.Delay ms={1200} />
                  <Typing.Backspace count={12} />
                  <Typing.Reset count={0} delay={500} />
                </Typing>
              </span>
            </div>
            <div className={styles.text}>
              <p>
              Understand your immune, thyroid, and brain function. Gain insights to improve your hypothyroidism, immune dysfunction, and cognitive function. Clear explanation of your at-home test results in one week.
              </p>
            </div>
            <div className={styles.price}>
              <span>
                <strong className={styles.green}>$29/month</strong>{" "}<s>$59/month</s>
              </span>
              <span className={styles.discount}>{" "}{" "}(<span className={styles.off}>50% off</span>)</span>
            </div>
            <div className={styles.biomarker}>
             Kit sent every 3 months. Biomarkers:
            </div>
            <div>
              {biomarkers.map((x, index) => (
                <span className={styles.borderbio} key={index}>
                  <div>{x.title}</div>
                </span>
              ))}
            </div>
            
            {/*
            <div className={styles.group}>
              {items.map((x, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size={x.size} />
                  </div>
                  <div className={styles.parameter}>{x.title}</div>
                  <div className={styles.content} style={{ color: x.color }}>
                    {x.content}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.list}>
              {list.map((x, index) => (
                <div className={styles.box} key={index}>
                  {x}
                </div>
              ))}
            </div>
            */}
            <div className={styles.btns}>
              {/*
              <Link className={styles.link} to="/privacy">
                <button className={cn("button-circle-stroke", styles.button)}>
                  <Icon name="lock" size="22" />
                </button>
              </Link>
              */}
              <a
                  href="https://buy.stripe.com/8wMg2H4Ll9j86E8fZg"
                  rel="noopener noreferrer"
                >
                <button className={cn("button", styles.button)}>
                  Order now
                </button>                
              </a>
            </div>
            <br />
            Free Shipping • FSA / HSA accepted • No Risk and Flexible 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
