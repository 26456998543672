import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Alpaca's Blog
        </div>
        <h1 className={cn("h1", styles.title)}>
        How wearables & AI are re-shaping healthcare
        </h1>
        <div className={cn("h5", styles.stage)}>
          FEBRUARY 13, 2023 • 3 MIN
        </div>

        <div className={styles.content}>
          <div>
          Wearables and artificial intelligence (AI) are revolutionizing the healthcare industry. From fitness trackers to smartwatches and AI-powered diagnostic tools, these technologies are transforming the way we approach healthcare and wellness. In this post, we'll take a look at how wearables and AI are reshaping healthcare and the potential benefits they offer.
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          Wearables unlock rich 24/7 health data
          </div>
          Wearable technology has made it easier for people to monitor their health and wellness 24/7. Fitness trackers, smartwatches, and other wearable devices allow users to track their physical activity, heart rate (HR), heart rate variability (HRV), abnormal heart rhythms, oxygen saturation, respiration, sleep quality, and more. This data can then be used to identify patterns and make lifestyle changes to improve overall health. For example, a user might discover that they need to gain a higher quality of sleep through improved sleep consistency or reduce their exertion levels and de-stress to re-balance their HRV.
          <br />
          <br />
          In addition to tracking daily health metrics, wearables can also be used to monitor and inform management of chronic conditions such as diabetes, heart disease, sleep apnea, Long COVID, and ME/CFS. For example, wearables can monitor key health variables such as sleep quality, HR, HRV, exertional levels, and periods of rest to guide pacing efforts to minimize the risk of post-exertional malaise (PEM) among Long COVID and ME/CFS sufferers. A wearable insulin pump can be used to automatically administer insulin to a patient with diabetes, reducing the risk of insulin-related complications. Wearables can also be used to remotely monitor the symptoms of heart disease, allowing for early intervention and treatment. 
          <br />
          <br />
          <div className={styles.preview}>
            <img
              src="/images/content/apple_watch.png"
              alt="Apple Watch, wearables and artificial intelligence for healthcare"
              />
          </div>
          <div className={cn("h5", styles.section)}>
          AI is the brain power that provides actionable insights from health data
          </div>
          AI is playing an increasingly important role in healthcare, from improving diagnostic accuracy to optimizing treatment plans. AI algorithms can analyze large amounts of data from wearable devices, electronic health records, and other sources to identify patterns and predict potential health issues. For example, AI algorithms can analyze a patient's sleep patterns to determine if they are at risk for sleep apnea. This information can then be used to make personalized recommendations for treatment and lifestyle changes. In the case of Long COVID or ME/CFS, AI can digest large quantities of wearable, biomarker, symptom, and intervention data to identify triggers and exertion thresholds, and calculate pacing scores to guide pacing efforts on a daily or hourly basis that help to minimize the risk of post-exertional malaise (PEM).
          <br />
          <br />
          AI is also being used to develop new drugs and therapies. For example, AI algorithms can analyze genomic data to identify new targets for drug development and to predict which patients are likely to respond to a particular treatment. This can help reduce the time and cost of drug development and improve patient outcomes. This is especially important for folks struggling with complex chronic illnesses as care access and quality is severely lacking, leading to unacceptable patient outcomes over an extended period of time.   
          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          Benefits of Wearables and AI in healthcare:
          </div>
          The integration of wearables and AI into healthcare offers many potential benefits, including:
          <br />
          <br />
          1. Improved Diagnostic Accuracy: AI algorithms can analyze large amounts of data to identify patterns and predict potential health issues, improving diagnostic accuracy and reducing the risk of misdiagnosis.
          <br />
          <br />
          2. Improved Access to Care: Wearables and AI can be used to remotely monitor patients, reducing the need for in-person visits and improving access to care for patients in medical deserts, often found in rural or remote areas.
          <br />
          <br />
          3. Personalized Treatment: Wearables and AI can be used to collect and analyze data about an individual's health and lifestyle, allowing for the development of personalized treatment plans.
          <br />
          <br />
          4. Improved Patient Outcomes: By monitoring health metrics, identifying potential health issues early, and providing personalized solutions, wearables and AI can help improve patient outcomes and reduce the risk of complications.
          <br />
          <br />
          5. Reduced Healthcare Costs: By improving diagnostic accuracy, reducing the need for in-person visits, and optimizing treatment plans, wearables and AI can help reduce healthcare costs.
          <br />
          <br />
          Wearables and AI are transforming the healthcare industry, offering a more personalized and data-driven approach to health and wellness. By improving diagnostic accuracy, reducing the need for in-person visits, and optimizing treatment plans around personal needs, wearables and AI have the potential to improve patient outcomes and reduce healthcare costs. Wearables and AI are unlocking new frontiers of complex chronic care management, and we at Alpaca Health are excited about what we can build together to improve care access and outcomes for the hundreds of millions of sufferers globally. 
          <br />
          <br />
          If you found this (or other) blogs helpful, we’d love to hear from you at support@joinalpaca.com or on Twitter at @Alpaca_Health. To receive the latest Long COVID and ME/CFS research, our blog posts, and product updates, please <a href="https://www.joinalpaca.com/newsletter">sign-up for our newsletter</a>.
          <br />
          <br/>
          Join the herd!
          <br/>
          <br />
          <div>Team Alpaca</div>
          <br />
          <div>Twitter: <a href="https://twitter.com/Alpaca_Health">@Alpaca_Health</a></div>
          <div>TikTok: <a href="https://www.tiktok.com/@alpacahealth">@alpacahealth</a></div>

        </div>
      </div>
    </div>
  );
};

export default Plan;
