import React from "react";
import cn from "classnames";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";
import { Popup } from 'react-typeform-embed';

const items = [
  {
    title: "Understand your health",
    color: "#45B26B",
    images: "/images/content/user.svg",
    alt: "user",
    content:
      "Increase insights of your own health over time and share with your doctors and care team."
  },
  {
    title: "Make better decisions",
    color: "#9757D7",
    images: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "Act on your insights with our Alpaca care team and work together to lower your inflammation."
  },
  {
    title: "100% Confidential and upfront pricing",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "We treat your data confidentially and follow HIPPA compliance in communication and storage."
  }
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/light-watch@2x.png 2x"
              srcSetDark="/images/content/dark-watch@2x.png 2x"
              src="/images/content/light-watch.png"
              srcDark="/images/content/dark-watch.png"
              alt="Book pic"
            />
          </div>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/ball@2x.png 2x"
              src="/images/content/ball.png"
              alt="Ball"
              className="photo"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/ball-green@2x.png 2x"
              src="/images/content/ball-green.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/test@2x.png 2x"
              src="/images/content/test.png"
              alt="Test"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Health is personal.</h2>
          <div className={styles.info}>
            Our team will personalize the pilot to your goals and help you take
            back control of your health.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.note}>
            <strong className={styles.green}>Lifetime discount</strong> if you sign
            up for our <strong className={styles.black}>Summer 2022 Pilot</strong>
          </div>
          <div className={styles.btns}>
            <Popup id="iJKyi3SKjEr" disableAutoFocus>
              <button
                className={cn("button", styles.button)}
              >
                <span>Get Early Access</span>
                <Icon name="arrow-right" size="10" />
              </button>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
