import React from "react";
import cn from "classnames";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";
import { Popup } from 'react-typeform-embed';
import { Link } from "react-router-dom";

const items = [
  {
    title: "1. Sign Up",
    color: "#45B26B",
    images: "/images/content/user.svg",
    alt: "user",
    content:
      "Fill out your details and receive your first at-home test kit. Optional - 360 health quiz and 20 minute call with a team member."
  },
  {
    title: "2. Provide Sample",
    color: "#9757D7",
    images: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "Follow the instructions in the kit and use the provided shipping label to return your sample to the lab."
  },
  {
    title: "3. Get Insights",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "After 5-7 business days, receive your biomarker results and evidence-based action plan."
  },
  {
    title: "4. Repeat",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Repeat tests every 2, 4, or 6 months to track your improvements and gain an updated action plan."
  }
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/alpaca-stress.png"
              srcSetDark="/images/content/alpaca-stress.png"
              src="/images/content/alpaca-stress.png"
              srcDark="/images/content/alpaca-stress.png"
              alt="App"
            />
          </div>
          {/** */}
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            {/* 
            <img
              srcSet="/images/content/test@2x.png 2x"
              src="/images/content/test.png"
              alt="Test"
            />

            */}
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>4 simple steps</h2>
          {/*<div className={styles.info}>
            Our team will personalize the program to your goals and help you take
            back control of your health.
          </div>*/}
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.note}>
            <strong className={styles.green}>Lifetime discount</strong> if you order today using code <strong className={styles.black}>LIFETIME25</strong>
          </div>
          <div className={styles.btns}>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            to={{
              pathname: "/onboarding-quiz" 
            }}
          >
            <button
                className={cn("button", styles.button)}
              >
                <span>Join our waitlist</span>
                <Icon name="arrow-right" size="10" />
            </button>
          </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
