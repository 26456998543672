import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Programs.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import ScrollParallax from "../ScrollParallax";

const items = [
  {
    title: "Essential Panel (1-7)",
    url: "/plans",
    cta: "Join our waitlist",
    color: "#45B26B",
    image: "/images/content/user.svg",
    alt: "user",
    content:
      `hs-CRP, ferritin, vitamin B12, vitamin D25-hydroxy, T3 (Triiodothyronine), T4 (Free Thyroxine), and TSH`
  },
  {
    title: "Expanded Panel (8-18)",
    url: "/plans",
    color: "#9757D7",
    cta: "Join our waitlist",
    image: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "Essential Panel + HbA1c, albumin, ALT, AST, bilirubin, BUN, creatinine, total cholesterol, LDL, HDL, and triglycerides"
  },
  {
    title: "Learn more",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "For more details on our 18 biomarkers and how they affect you, please refer to our FAQs on the About Us page."
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Programs = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={cn(classNameSection, styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn(styles.title, classNameTitle)}>{title}</div>
        </div>
        <div className={styles.wrap}>
          <Slider className="programs-slider" {...settings}>
            {items.map((x, index) => (
              <ScrollParallax className={styles.slide} key={index}>
                <div className={cn("programs-item", styles.item)}>
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: x.color }}
                  >
                    <img src={x.image} alt={x.alt} />
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  <Link
                    className={cn("button-stroke", styles.button)}
                    to={x.url}
                  >
                    {x.cta}
                  </Link>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Programs;
