import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Programs.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import ScrollParallax from "../ScrollParallax";

const items = [
  {
    title: "hs-CRP",
    url: "/plans",
    cta: "Explore Plans",
    color: "#3772FF",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      `Protein produced by the liver in response to inflammation in your body. It may be elevated due to certain chronic illnesses, medications, or recent illnesses. hs-CRP is often used as part of a broader, comprehensive assessment evaluating heart health, chronic inflammatory diseases, and characteristically elevated in inflammatory thyroid disorders.`
  },
  {
    title: "Ferritin",
    url: "/plans",
    color: "#3772FF",
    cta: "Explore Plans",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      `This iron storage protein is key to resolving thyroid hair-loss, fatigue, restless leg, cold intolerance, and feeling unwell despite thyroid meds. Ferritin levels are also key to understanding how efficiently oxygen is getting delivered to all areas of your body. If ferritin is lower than normal, it indicates an iron deficiency, which could result in anaemia and symptoms including, dizziness, headaches, shortness of breath, and rapid heartbeat.`
  },
  {
    title: "TSH (Thyroid-Stimulating Hormone)",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Thyroid-stimulating hormone, TSH, is the hormone responsible for controlling hormone production by the thyroid gland. The hormone TSH is considered the most sensitive marker for screening for thyroid diseases and conditions."
  },
  {
    title: "T3 (Triiodothyronine)",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Thyroid hormone that affects almost every physiological process in your body, including growth and development, metabolism, body temperature, and heart rate. Levels of T3 fluctuate when individuals have an under or overactive thyroid gland."
  },
  {
    title: "FT4 (Free Thyroxine)",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
    "Free T4 is the predominant hormone produced by the thyroid gland. Levels fluctuate when individuals have an under or overactive thyroid gland. Together, with T3, these hormones regulate a number of your body's functions, including temperature, metabolism, heart rate, brain development, nervous system, cholesterol, breathing, etc."
  },
  {
    title: "Vitamin D25-Hydroxy",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Vitamin D has many important roles in the body, including bone formation and maintenance as well as immune function, among others. Epidemiological research has identified vitamin D as a risk factor for more severe COVID-19. Managing Vitamin D levels can help with depression, antibodies and pain."
  },
  {
    title: "Vitamin B12",
    url: "/about",
    color: "#3772FF",
    cta: "About Us",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Vitamin that affects your overall energy, concentration, focus and memory. This is  commonly low and can lead to low energy, brain fog, and tingling arms, and legs. Also known as Cobalamin, Vitamin B12 is important for the normal formation of red blood cells and the health of nerve tissues. Neurological features of Long COVID, Hashimoto's, and Vitamin B12 deficiency overlap."
  },
  
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Programs = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={cn(classNameSection, styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn(styles.title, classNameTitle)}>{title}</div>
          {/*<div className={styles.info}>
            This <a href="https://www.nature.com/articles/s41579-022-00846-2" target="_blank"> study published in Nature</a> in January 2023 pointed out that Long COVID symptoms can crop in the heart, lungs, immune system, pancreas, GI track, gidneys, neurological system, liver, blood vessels, reproductive system and more.
  </div>*/}
7-in-1 comprehensive at-home blood test kit to track your critical immune, brain, and thyroid health biomarkers. Stop guessing. Start measuring.
        </div>
        <div className={styles.wrap}>
          <Slider className="programs-slider" {...settings}>
            {items.map((x, index) => (
              <ScrollParallax className={styles.slide} key={index}>
                <div className={cn("programs-item", styles.item)}>
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: x.color }}
                  >
                    <img src={x.image} alt={x.alt} />
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  {/*<Link
                    className={cn("button-stroke", styles.button)}
                    to={x.url}
                  >
                    {x.cta}
            </Link>*/}
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Programs;
