import React, { useRef } from "react";
import styles from "./ExpandedPanel.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";
import FAQ from "../FAQ/Faq";
import Headers from "../../components/Header";
import Programs from "../../components/Programs";
import Offer from "../../components/Offer";


const ExpandedPanel = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero />
      <Programs
        classNameSection="section-pb"
        classNameTitle="h2"
        title="CLIA-Certified Results"
      />
      <Offer className="section-border-top" />

    </>
  );
};

export default ExpandedPanel;
