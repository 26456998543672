import React from "react";
import cn from "classnames";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";
import { Popup } from 'react-typeform-embed';
import { Link } from "react-router-dom";

const items = [
  {
    title: "1. At-home blood test",
    color: "#45B26B",
    images: "/images/content/user.svg",
    alt: "user",
    content:
      " Complete your pain-free test where you’re comfortable — no needles, simple finger-prick, finished in a couple minutes."
  },
  {
    title: "2. Results in 1 week",
    color: "#9757D7",
    images: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "Receive clear results you can trust — simple and comprehensive, analyzed by CLIA-certified labs, reviewed by US physicians."
  },
  {
    title: "3. Ongoing support",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "Track changes over time — we’ll check-in to see how you’re feeling, and can provide ongoing thyroid, immune, and cognitive testing and support."
  },
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/alpaca_actual.png"
              srcSetDark="/images/content/alpaca_actual.png"
              src="/images/content/alpaca_actual.png"
              srcDark="/images/content/alpaca_actual.png"
              alt="App"
            />
          </div>
          {/** */}
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            {/* 
            <img
              srcSet="/images/content/test@2x.png 2x"
              src="/images/content/test.png"
              alt="Test"
            />

            */}
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h4", styles.title)}>Three Steps. It's Simple.</h2>
          <div className={styles.info}>
          Our at-home test kits let you test wherever you are, including the comfort of your couch.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Book;
