import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Alpaca's Blog
        </div>
        <h1 className={cn("h1", styles.title)}>
        How can pacing help to manage LC & ME/CFS symptoms?
        </h1>
        <div className={cn("h5", styles.stage)}>
          JANUARY 31, 2023 • 4 MIN
        </div>

        <div className={styles.content}>
          <div>
          Based on our research, nearly 75% of Long COVID and ME/CFS sufferers find pacing and avoiding post-exertional malaise (PEM) - a worsening of symptoms following exertion - the most challenging aspect of managing their chronic condition. This blog post aims to demystify PEM and pacing by delving into what they are, how pacing can help manage PEM, what factors should be considered when implementing pacing techniques, and some pacing techniques that could be adopted or adapted to combat ongoing symptoms and PEM. 
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          What is PEM and why is it so difficult to avoid?
          </div>
          PEM is the worsening of symptoms associated with Long COVID, ME/CFS, and other chronic conditions following physical, mental, and/or emotional exertion. The triggering level of exertion doesn’t need to be extreme, in fact, it is quite often only a mild event that brings on a PEM episode. Symptoms typically deteriorate 12 to 48 hours after the trigger event takes place and can remain elevated for hours, days, or weeks. PEM is particularly difficult to predict and manage given the different types and degrees of exertion that can cause a PEM episode, and the variation in the delayed onset of exacerbated symptoms.
          <br />
          <br />

          <div className={styles.previewmd}>
            <img
              src="/images/content/pem.jpeg"
              alt="post-exertional malaise - PEM, PESE, CFS, Long COVID, ME/CFS"
              />
          </div>
          <div className={cn("h5", styles.section)}>
          What is pacing and how can it help?
          </div>
          Let’s tackle this head-on - managing PEM is really difficult! There’s a reason most Long COVID and ME/CFS sufferers find it to be the most difficult thing to manage. However, pacing or exertion restriction is a technique that can be used to manage PEM flare-ups. Pacing involves understanding an individual’s propensity to suffer from PEM, their energy levels, their energy consumption during different activities, and their exertion thresholds that heighten the risk of PEM. Once these variables are known, steps can be taken to keep energy consumption below storage levels, and avoiding particular activities and thresholds that increase the risk of a PEM flare-up.
          <br />
          <br />
          <div className={styles.previewlg}>
            <img
              src="/images/content/managing_pem.png"
              alt="Managing post-exertional malaise - PEM, PESE, CFS, Long COVID, ME/CFS"
              />
          </div>
          While aerobic exercise can be beneficial for many chronic illnesses, patients with Long COVID or ME/CFS typically do not tolerate typical exercise routines. Standard exercise recommendations for healthy individuals can be harmful to patients with Long COVID or ME/CFS. If feasible, it is a good idea for patients with Long COVID or ME/CFS to maintain activities they can tolerate as deconditioning can also be harmful. For some patients, it first might be necessary to treat orthostatic intolerance (i.e., the inability to remain upright without symptoms) and improve a their capacity to tolerate being upright before any further activity can be incorporated.          <br />
          <br />
          <div className={cn("h5", styles.section)}>
          What factors need to be considered when trying to effectively pace yourself? 
          </div>
          There is no perfect formula for avoiding PEM just yet, and the severity and triggers are different for different people, so it’s crucial to listen to your own body and adjust your pacing techniques as you discover what does and doesn’t work. Below are a few of the most important variables to consider when trying to develop a pacing routine to avoid PEM.          <br />
          <div>
          • Pre-existing medical conditions and/or ongoing medications and their side-effects <br />
          • Symptom type, frequency, and severity, especially following periods of physical, mental, and emotional exertion <br />
          • Sleep quality, duration, and consistency <br />
          • Heart rate (average resting HR as well as maximums and minimums) <br />
          • Heart rate variability (HRV) (average as well as maximums and minimums); HRV is the variation in time between consecutive heartbeats; it is an indicator of sympathetic and parasympathetic nervous system activity and a proxy for the amount of stress the body is under <br />
          • Exercise frequency, duration, and intensity (step counters and heart rate monitors can be used as a proxy to measure the intensity of some physical activities) <br />
          • Nutrition quality, consistency, and eating schedules<br />
          • Ongoing levels of hydration <br />
          • Inform your care team of your pacing strategy, and seek medical attention if your symptoms deteriorate or don't improve  <br />
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          What pacing techniques can be adopted or adapted to help manage PEM?
          </div>
          <div>
          As highlighted above, pacing to avoid PEM is no easy feat but it is not impossible. The below tools can offer support by digesting some of the important factors above to help guide your pacing efforts. From our experience, none of the existing offerings were particularly helpful - that’s why we’ve worked with patients and cross-discipline specialists to develop our own!
          </div>
          <br />
          <div>
          • <u>Alpaca’s Pacing Partner:</u> An estimate of an individual’s daily exertion readiness with the objective of minimizing PEM. Calculations are based on an individual’s health baseline, biomarkers, symptoms, PEM episodes, pacing, heart rate, HRV, sleep, exertion, nutrition, and eating patterns. <br />
          • <u>Spoon Theory:</u> An energy assessment rubric to estimate daily energy reserves and likely consumption across activities. The quota for daily energy reserves (or “spoons”) is based on a self-assessment of sleep, nutrition, general well-being, and medication adherence. This quota is used to plan daily living activities based on a standardized grading of how many “spoons” each activity is likely to consume.  <br />
          • <u>WHO Borg CR-10 5-phase protocol:</u> A physical exertion assessment rubric used to guide gradual increases to physical exertion over time. Exertion levels are measured on a 0-10 self-reported scale and categorized into 5 phases, with a minimum of 7 days to be spent at each phase, assuming the individual remains symptom-free.  <br />
          • <u>Garmin Body Battery</u>: An estimate of daily energy levels between 0-100 for an average, healthy individual, based on their HRV, sleep, and activity data.  <br />
          • <u>Garmin Training Readiness:</u>A close cousin of the Garmin Body Battery - a estimate of daily exercise preparedness for an average, healthy individual, based on resting heart rate, HRV, sleep, and activity data. This calculation is especially focused on the duration since the last episode of physical exertion. <br />
          • <u>Standardized activity schedule:</u>A schedule of daily or weekly activities to avoid overexertion and exacerbating symptoms. This can include setting aside specific times for rest and relaxation, varying the types of activities, and gradually increasing the amount of activity completed over time. While a schedule can be helpful, it's typically more important to listen to your body and take breaks when you need to. <br />
          </div>
          <br />
          <div className={cn("h5", styles.section)}>
          WHO's Borg Rating of Perceived Exertion (RPE) Category Ratio CR-10 Scale for PEM management
          </div>
          <div className={styles.preview}>
            <img
              src="/images/content/borg.png"
              alt="WHO's Borg Rating of Perceived Exertion (RPE) Category Ratio CR-10 Scale for PEM management"
              />
          </div>
          <div>
          While there is no silver bullet to avoid PEM, we hope the above guide provides some clarity on what it is and how personalized pacing techniques can be used to help minimize flare-ups. We’re running a pilot using our AI Companion and Pacing Partner with a limited number of Long COVID and ME/CFS participants - <a href="/newsletter">register your interest here ASAP</a>. We’ll be in touch to confirm those who have secured a place.
          </div>

          <br/>
          <div>Join the herd!<div>
          <br/>
          <div>Team Alpaca</div>

          <br />
          <br />
          <div>Twitter: <a href="https://twitter.com/Alpaca_Health">@Alpaca_Health</a></div>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Plan;
