import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Sign-Up",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "Onboard and establish your health baseline with our 360° quiz"
  },
  {
    title: "Track Symptoms",
    color: "#EF466F",
    images: "/images/content/medal.svg",
    content:
      "Our friendly AI Companion analyzes your key health data"
  },
  {
    title: "Gain Insights",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "Understand your triggers and discover interventions" 
  },
  {
    title: "Ongoing Testing",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "Monitor curated biomarkers to improve whole-body health",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h3", styles.title)}>Whole-person approach</h2>
          <div className={styles.info}>
          We offer a whole-person and evidence-based approach to help you take control of your health          
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
