import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "General",
    items: [
      {
        title: "How do I avoid post-exertional malaise (PEM)?",
        answer: `Minimizing PEM is a highly individual journey. Understanding your energy reserves and managing your sleep, stress, exertion (physical, mental, and emotional), and nutrition can help to minimize PEM. We take a personalized, data-driven approach to understand how best to manage your PEM triggers and thresholds.`
      },
      {
        title: "What is Alpaca's 360° Quiz?",
        answer: "Your responses to the 360° Quiz supplements our AI companion to enhance our understanding of your whole-body health over time. We ask a range of questions about your medical history and symptom presentation to better understand your general, cardiorespiratory, nervous system, musculoskeletal, digestive, neurological, and mental health." 
      },
      {
        title: "What is Alpaca's Cognitive Test?",
        answer: "We use a standardized instrument to detect cognitive impairment. The exam evaluates your thinking abilities and helps us to evaluate your baseline and recovery progress. This is not a formally administered and proctored cognitive assessment and is used exclusively to screen and assess your cognitive levels."
      },
    ]
  },
];


const Team = () => {
  const options = [];
  items.map(x => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn("stage-small", styles.stage)}>
          </div>
          <h2 className={cn("h2", styles.title)}>Frequently Asked Questions (FAQs)</h2>
         
          
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find(x => x.title === category)
            .items.map((x, index) => (
              <Item item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
