import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Interesting.module.sass";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card";

const items = [
  {
    title: "Alpaca's Essential Blood Test Panel",
    url: "/at-home-quarterly-7-in-1-plan",
    image: "/images/content/essential_panel.png",
    image2x: "/images/content/essential_panel.png",
    category: "blue",
    categoryText: "Immune-Brain | Thyroid ",
    avatar: "/images/content/avatar-1.png",
    trainer: "Alpaca's Essential Blood Test Panel",
    level: "blue",
    levelText: "Essential",
    counterVideo: "7",
    counterUser: "160",
  }
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Interesting = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <h2 className={cn("h3", styles.title)}> Alpaca's Quarterly 7-in-1 Plan</h2>
          <div className={styles.text}>Gain insights from our 7-in-1 Essential Blood Test Kit. Track your critical immune, brain, and thyroid health markers. Stop guessing. Start measuring.</div>
          <div className={styles.wrap}>
            <Slider className="interesting-slider" {...settings}>
              {items.map((x, index) => (
                <div className={styles.slide} key={index}>
                  <Card className={styles.card} item={x} />
                </div>
              ))}
            </Slider>
          </div>
          <div className={styles.btns}>
              {/*
              <Link className={styles.link} to="/privacy">
                <button className={cn("button-circle-stroke", styles.button)}>
                  <Icon name="lock" size="22" />
                </button>
              </Link>
              */}
              <a
                  href="https://buy.stripe.com/7sI5o391B7b08Mg28p"
                  rel="noopener noreferrer"
                >
                <button className={cn("button", styles.button)}>
                  Order now
                </button>                
              </a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Interesting;
