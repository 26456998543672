import React from "react";
import Post from "./Plan";
import Offer from "../../../components/Offer";


const Download = () => {
  return (
    <>
      <Post />
      <Offer />
    </>
  );
};

export default Download;
