import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import History from "./History";
import Item from "./Item";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Featured",
    items: [
      {
        url: "/blog/how-wearables-and-ai-are-reshaping-healthcare",
        title: "How wearables & AI are re-shaping healthcare",
        date:"Feb 13, 2023",
        author: "Team Alpaca",
        avatar: "/images/logo.svg",
        image: "/images/content/apple_watch.png",
        category: "green",
        cta: "Read more",
        categoryContent: "Technology"
      },
      {
        url: "/blog/mental-health-learnings-from-jonah-hill-movie-stutz",
        title: "Mental health learnings from Jonah Hill’s movie “Stutz”",
        date:"Feb 09, 2023",
        author: "Team Alpaca",
        avatar: "/images/logo.svg",
        image: "/images/content/jonah.jpeg",
        category: "pink",
        cta: "Read more",
        categoryContent: "Wellness"
      },
      {
        url: "/blog/how-can-pacing-help-to-manage-lc-and-me-cfs-symptoms",
        title: "How can pacing help to manage LC & ME/CFS symptoms?",
        date:"Jan 31, 2023",
        author: "Team Alpaca",
        avatar: "/images/logo.svg",
        image: "images/content/pem.jpeg",
        category: "red",
        categoryContent: "Science"
      },
      {
        url: "/blog/how-does-long-covid-and-me-cfs-affect-your-body",
        title: "How does Long COVID & ME/CFS affect your body?",
        date:
          "Jan 25, 2023",
        author: "Team Alpaca",
        avatar: "/images/logo.svg",
        image: "images/content/long_covid_symptoms.png",
        category: "red",
        categoryContent: "Science"
      },
      {
        url: "/blog/why-we-chose-to-focus-on-long-covid-and-me-cfs-in-the-us",
        title: "Why we chose to focus on Long COVID and ME/CFS in the US",
        date:
          "Dec 19, 2022",
        author: "Team Alpaca",
        avatar: "/images/logo.svg",
        image: "/images/content/covid-viral.jpeg",
        category: "green",
        categoryContent: "Our motivation"
      }
    ],
  },
];

const Hero = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        {/* 
        <h2 className={cn("hero", styles.title)}>Blog</h2>
       
        <div className={styles.info}>
          Track your workouts, get better results, and be the best version of
          you. Less thinking, more lifting.
        </div>
        */}
        <History />
        
        <div className={styles.nav}>
          {items.map((x, index) => (
            <button
              className={cn(styles.btn, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.list}>
          {items[activeIndex].items.map((x, index) => (
            <ScrollParallax className={styles.box} key={index}>
              <Item item={x} className={styles.item} />
            </ScrollParallax>
          ))}
        </div>
        {/* 
        <div className={styles.btns}>
          <button className={cn("button-stroke button-small", styles.button)}>
            Load more
          </button>
        </div>
          */}
      </div>
    </div>
  );
};

export default Hero;
