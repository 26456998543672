import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Iframe from 'react-iframe'


const Privacy = ({ scrollToRef }) => {
  const iframeRef = React.createRef();
  const [iframeHeight, setIframeHeight] = React.useState('100%');

  return (
    <div>
        <Iframe
            url='https://share.hsforms.com//1VMbmxCEiQwWMWDAWxsOyawdtujr'
            width="100%"
            height="1000px"
            id=""
            className=""
            display="block"
            position="relative"
        />
    </div>
  );
};

export default Privacy;
