import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";
import Theme from "../Theme";
import { Popup } from 'react-typeform-embed';
import Subscription from "../Subscription";

const navLinks = [
  {
    title: "About Us",
    url: "/about"
  },
  {
    title: "Blog",
    url: "/blog"
  },
];

const socials = [
  {
    title: "linkedin",
    size: "18",
    url: "https://www.linkedin.com/company/alpaca-health/"
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/alpacahealth"
  },
  {
    title: "calendar",
    size: "18",
    url: "mailto: support@hyperbeam.ai"
  }
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
          <Link
            to="/"
            onClick={() => setVisibleNav(false)}
          >
            <Image
              src="/images/logo.svg"
              srcDark="/images/logo.svg"
              alt="Alpaca Health"
            />
          </Link>
          <p className={styles.name}>Alpaca Health</p>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.content ? (
                <DropdownMenu item={x} key={index} setValue={setVisibleNav} />
              ) : 
                  <Link
                    className={styles.link}
                    activeClassName={styles.active}
                    to={{
                      pathname: x.url
                    }}
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </Link>
              
            )}
          </nav>
          <div className={styles.details}>
           <Theme className={styles.theme} />
            <div className={styles.btns}>
              <Subscription
                className={styles.subscription}
                placeholder="Learn More &#38; Get Early Access"
                maintext="Join our waitlist"
              />
            </div>
          </div>
        </div>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
