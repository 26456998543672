import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <h1 className={cn("h3", styles.title)}>
          Why Regular Testing Is Important
          </h1>
          <div className={styles.text}>
          Planes have a flight management system and dashboard to inform pilots of real-time aircraft health and when action is needed. Our bodies are more complex than a plane.{" "}<b>We need to better understand our health.</b>
          <br /><br/> Alpaca Health provides AI tools, resources, and support to measure and manage immune dysfunction, hypothyroidism, and cognitive function.  <b>Alpaca is your real-time health management system.</b>
          <br/>
          <br />
          Better understand your whole-body health and gain insights on how to make improvements. <b>Take control and live life with freedom.</b>
          
          <div className={styles.btns}>
              {/*
              <Link className={styles.link} to="/privacy">
                <button className={cn("button-circle-stroke", styles.button)}>
                  <Icon name="lock" size="22" />
                </button>
              </Link>
          */}
              <a
                  href="https://buy.stripe.com/8wMg2H4Ll9j86E8fZg"
                  rel="noopener noreferrer"
                >
                <button className={cn("button", styles.button)}>
                  Order now
                </button>                
              </a>
  
            </div>
            <br />
            Free Shipping • FSA / HSA accepted • No Risk and Flexible 
            <br />
          </div>
        </ScrollParallax>

        <div className={styles.gallery}>
            <video className={styles.video} loop autoPlay muted playsInline poster={"/content/mobile_background.png"}>
                <source
                    src="/videos/background_video.mp4" 
                    type="video/mp4"
                />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Hero;
