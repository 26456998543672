import React from "react";
import styles from "./ExpandedPanel.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";
import FAQ from "./Faq";

const EssentialPanel = () => {
  return (
    <>
      <Hero />
      <FAQ />
      <Interesting />
    </>
  );
};

export default EssentialPanel;
