import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import { Widget } from 'react-typeform-embed';

const Privacy = ({ scrollToRef }) => {
  const iframeRef = React.createRef();
  const [iframeHeight, setIframeHeight] = React.useState('100%');

  return (
    <div>
        <Widget id="DGIujCIN" height={600} />
          
    </div>
  );
};

export default Privacy;
