import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "General",
    items: [
      {
        title: "What biomarkers are measured?",
        answer: `We've selected 7 biomarkers: hs-CRP, Ferritin, Vitamin B12, Vitamin D 25-Hydroxy, T3 Free, T4, and TSH. In contrast, our Expanded Panel covers 18 biomarkers across organs which includes a metabolic and lipid panel. Your health is more than a snapshot and should be evaluated on a continuum.`
      },
      {
        title: "How do these biomarkers affect me?",
        answer: `1. hs-CRP (high-sensitivity C-reactive protein) is a non-specific, acute phase protein that the liver makes in response to inflammation in the body. It may be elevated due to certain chronic illnesses, medications, or recent illnesses. hs-CRP can be used in the clinical setting as part of a broader, comprehensive assessment evaluating heart health.
        \n2. Ferritin - A blood cell protein that indicates how much iron your body is storing. Ferritin levels are key to understanding how efficiently oxygen is getting delivered to all areas of your body. If a ferritin test reveals that it is lower than normal, it indicates your body’s iron stores are low and you have iron deficiency. As a result, you could be anaemic with symptoms: including dizziness, headaches, shortness of breath, rapid heartbeat.
        \n3. Vitamin B12 - Vitamin B12 affects your overall energy, concentration, focus and memory. Also known as Cobalamin, Vitamin B12 is important for the normal formation of red blood cells and the health of nerve tissues. Neurological features of COVID-19 and vitamin B12 deficiency overlap. 
        \n4. Vitamin D 25-Hydroxy - Vitamin D has many important roles in the body, including bone formation and maintenance as well as immune function, among others. Epidemiological research has identified vitamin D as a risk factor for more severe COVID-19.
        \n5. T3 - Thyroid hormone that affects almost every physiological process in the body, including growth and development, metabolism, body temperature, and heart rate.
        \n6. Free T4 - The Thyroid produces a hormone called Thyroxine, known as T4. Together, with T3, these hormones regulate your body's temperature, metabolism, heart rate, brain development, nervous system function, Cholesterol levels, breathing, etc.
        \n7. TSH - Thyroid-stimulating hormone (also known as Thyrotropin, Thyrotropic hormone or abbreviated TSH) is a pituitary hormone that stimulates the Thyroid gland to produce Thyroxine (T4), and then Triiodothyronine (T3)`
      },
      {
        title: "How long is the process?",
        answer: "The entire test panel process should take less than 20 minutes and should take 5-7 business days for results."
      },
      {
        title: "Where is my lab work done?",
        answer: "Your samples are analyzed at a high-complexity laboratory that is CLIA and CAP accredited, located in San Diego, CA. CLIA Number: 05D2130115 and CAP Number: 8089263."
      },
      {
        title: "How do I take my at-home blood test?",
        answer: `Testing is a straightforward process. Testing requires a bit of preparation, your blood sample, and little to no pain. For best results, we recommend taking the test after fasting 10-12 hours and taking it in the morning before breakfast. Then follow, the below instructions:
        \n1. Proper hydration - hydration helps blood flow; please make sure to drink two glasses of water 30 minutes before testing.
        \n2. Complete your information card & register - ‍Fill out the collection card with your name, today’s date, and your date of birth. Then, scan the QR code on the front of the card or visit results.zizi.co/register and enter your kit ID. (Next to the QR code.)
        \n3. Get your blood flowing - Warm your hands with hot water or rub them together for a few minutes, then shake your hands below your waist.
        \n4. Choose a finger - Wipe the tip of your middle or ring finger with the alcohol wipe.
        \n5. Prick your finger - Twist off the lancet cap. (You will not see the needle.) Press the lancet into the side of your finger to puncture the skin. Allow the first drop of blood to drip onto the provided gauze.
        \n6. Collect your blood - On just one small square, drip 4-6 drops of blood. Gently squeeze your finger downward to help release more blood; blood will flow to the right of the card. Do not touch the card.
        \n7. Apply a Bandaid - Apply the bandage to your finger.
        \n8. Pack up sample & return - Once the sample appears dry to the touch, put the card into the foil bag and the bag into the return envelope, then mail. Once our lab receives the sample, it will be tested and you will be notified of your results. Results are typically made available within two business days after receiving your sample back.`
      },
      {
        title: "What should my blood spot sample look like?",
        answer: `• Your name and date should be on the card
        \n• Your blood sample should fill the collection square using large drops of blood
        \n• Your blood sample should spread to the line indicated on the card
        \n• Your blood sample should NOT be smeared on the outside of the card or elsewhere on the square`,
        image: "/images/content/blood_spot_example.png",
        info: "Please refer to the picture"
      },
      {
        title: "What if I can't get enough blood?",
        answer: `Blood must be taken in one sitting. You cannot collect some and then come back later to collect more, this will result in a failed test. Please watch our video below to learn more about taking our test.
        \nIf you find it too difficult to draw your blood, one of our team members will gladly schedule a video call with you to answer questions and give support.`
      },
      {
        title: "What is Alpaca's Post COVID Quiz?",
        answer: "Our quiz can assist in helping to pick which test panel to choose by assessing your symptoms. We ask several questions to understand your Long COVID symptoms and severity regarding your general health, cardiorespiratory health, nervous system health, mental health, musculoskeletal health, digestive health, neurological and quality of life. Our program is a support tool to help you in your Long COVID recovery. If your symptoms worsen or do not get better, please consult your doctor." 
      },
      {
        title: "What is Alpaca's Cognitive Test?",
        answer: "We are using a reliable instrument for detecting cognitive impairment. The exam evaluates your thinking abilities and helps our team and yourself evaluate where you are in your recovery. This is not a formally administered and proctored cognitive assessment and is used exclusively to screen and assess your cognitive levels."
      },
    ]
  },
];


const Team = () => {
  const options = [];
  items.map(x => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn("stage-small", styles.stage)}>
            Frequently Asked Questions (FAQs)
          </div>
          <h2 className={cn("h2", styles.title)}>Common Questions</h2>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find(x => x.title === category)
            .items.map((x, index) => (
              <Item item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
