import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trainer.module.sass";
import Item from "./Item";

const items = [
  {
    name: "Dr. Phetsouphanh",
    type: "Long COVID Researcher",
    avatar: "/images/content/oxford_postdoc.png",
    review: [
      {
        author: "Dr. Phetsouphanh",
        description: "UNSW Long COVID Researcher, Oxford University Postdoc",
        logo: "/images/content/goldlines.svg",
        image: "/images/content/long-covid.png",
        image2x: "/images/content/long-covid@2x.png",
        comment:
          "In Long COVID patients [researchers] have uncovered evidence of prolonged inflammation and activation of the immune response for at least 8 months after initial infection…what we’re seeing with Long COVID is that even when the virus has completely left the body, the immune system remains switched. It’s unique to sufferers of Long COVID."
      }
    ]
  },
  {
    name: "Prof. Slavich",
    type: "UCLA Medical Professor",
    avatar: "/images/content/ucla_professor.png",
    review: [
      {
        author: "Prof. George Slavich     ",
        description: "Professor of Psychiatry & Biobehavioral Sciences at UCLA",
        logo: "/images/content/velocity9.svg",
        image: "/images/content/slavich.png",
        image2x: "/images/content/slavich@2x.png",
        comment: "People typically don’t know that they have chronic inflammation until it’s too late…chronic inflammatory diseases are the most significant cause of death in the world today, with more than 50 percent of all deaths being attributable to inflammation-related diseases.",
      }
    ]
  },
  {
    name: "Prof. Hotamışlıgil",
    type: "Harvard TH Chan Professor",
    avatar: "/images/content/harvard_professor.png",
    review: [
      {
        author: "Prof. Gökhan Hotamışlıgil",
        description: "Professor at Harvard T.H. Chan School of Public Health",
        logo: "/images/content/goldlines.svg",
        image: "/images/content/harvard_doctor.png",
        image2x: "/images/content/harvard_doctor@2x.png",
        comment:
          "Chronic inflammation is uniformly damaging and is absolutely causal to the process as a root cause of noncommunicable diseases [Cancer, Heart, Lung, Diabetes, Alzheimer's], because if you interfere with it, you can reverse the pathology."
      }
    ]
  },
  {
    name: "Dr. Greger, M.D. FACLM",
    type: "Physician, NYT Author",
    avatar: "/images/content/gregor.png",
    review: [
      {
        author: "Dr. Michael Greger, M.D. FACLM",
        description: "Physician, author, and professional speaker on public health",
        logo: "/images/content/goldlines.svg",
        image: "/images/content/diet.png",
        image2x: "/images/content/diet@2x.png",
        comment:
          "Four things cut our risk of developing a chronic disease by 78%. 95% of diabetes risk out the window, 80% of heart attack risk, gone. Half of stroke risk, a third of cancer risk, simply gone...Adhering to four simple healthy lifestyle factors can have a strong impact on the prevention of chronic diseases: not smoking, not being obese, exercising half an hour a day, and eating healthier."
      }
    ]
  },
];

const Trainer = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={cn(styles.section, className)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {items[activeIndex].review.map((x, index) => (
            <Item item={x} key={index} />
          ))}
        </div>
        <div className={styles.nav}>
          {items.map((x, index) => (
            <div
              className={cn(styles.link, {
                [styles.active]: index === activeIndex
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              <div className={styles.avatar}>
                <img src={x.avatar} alt="Avatar" />
              </div>
              <div className={styles.details}>
                <div className={styles.man}>{x.name}</div>
                <div className={styles.position}>{x.type}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
