import React, { useRef } from "react";
// import styles from "./Home.module.sass";
import Hero from "./Hero";
import TeamLogo from "../../components/TeamLogo";
import Steps from "../Home/Steps";
import Book from "../Home/Book";
import ValueProps from "../../components/ValueProps";
import Offer from "../../components/Offer";
import Testimonials from "../../components/Testimonials";

const Heart = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero scrollToRef={scrollToRef} />
      <Steps scrollToRef={scrollToRef} />
      <Book />
      <Testimonials />
      {/*<ValueProps/>*/}
      {/*<Plan className="section-border-top"/>*/}
      <Offer className="section-border-top" />
      {/*<Popup id="iJKyi3SKjEr" width={500} height={600} disableAutoFocus/>*/}
    </>
  );
};

export default Heart;
