import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";
import Typing from 'react-typing-animation';

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallerymobile}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/outcomes.png"
              srcSetDark="/images/content/outcomes.png"
              src="/images/content/outcomes.png"
              srcDark="/images/content/outcomes.png"
              alt="App"
            />
          </ScrollParallax>
        </div>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            AI Platform for Complex Chronic care
          </div>
          
          <h1 className={cn("h1", styles.title)}>
            <span>Take control of your {" "}</span>
            <span>
              <Typing loop="True" className={styles.typing}>
                <Typing.Speed ms={50} />
                <span>Long COVID</span>
                <Typing.Delay ms={3000} />
                <Typing.Speed ms={100} />
                <Typing.Backspace count={10} />
                <span>ME/CFS</span>
                <Typing.Delay ms={3000} />
                <Typing.Backspace count={6} />
                <span>Pacing/PEM</span>
                <Typing.Delay ms={3000} />
                <Typing.Backspace count={10} />
                <span>Workflows</span>
                <Typing.Delay ms={3000} />
                <Typing.Backspace count={10} />
                <Typing.Reset count={0} delay={500} />
              </Typing>
            </span>
          </h1>
          <div className={styles.text}>
          We're building AI infrastructure to scale complex chronic care and optimize research. Our multimodal AI and automation platform unlocks a new frontier of continuous care and clinical outcomes.
          
          <br />
          {/*
          <a style={{ fontWeight: 700 }}>
          Regain control of your health with integrative treatments following WHO/CDC guidelines.          
  </a> */}
          </div>
          <div className={styles.btns}>
            <Subscription
              className={styles.subscription}
              placeholder="Learn More &#38; Get Early Access"
              maintext="Join our waitlist"
            />
          </div>
          <div className={styles.text}>
          {/*
          <a style={{ fontWeight: 700 }}>
          Regain control of your health with integrative treatments following WHO/CDC guidelines.          
          </a> */}
          </div>
        </ScrollParallax>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/outcomes.png"
              srcSetDark="/images/content/outcomes.png"
              src="/images/content/outcomes.png"
              srcDark="/images/content/outcomes.png"
              alt="App"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
