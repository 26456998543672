import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Welcome to the Herd 🦙
          </div>
          <h1 className={cn("h3", styles.title)}>
          Thank you! We will process your order shortly.    
          </h1>
          <div className={styles.text}>
          As a Founding Herd Member, we greatly appreciate of your support towards our mission, and we look forward to working hard to partner with you on your health journey.
          <br /> <br />Please be on the lookout for an email with your 360° quiz and tracking info in the next 24-48 hours. If you have any questions, please email support@joinalpaca.com. Congrats on taking a step forward in your health journey! 🎉          
          {/*Our clinical advisory board members are medical directors from world-class institutions like Mayo Clinic's Post-COVID-19 Care Clinic, Harvard Medical School, and Massachusetts General Hospital. */}            
          <br />
          <br />
            <p>If you haven't already, we encourage you to take advantage of our free AI pilot alongside world-class clinicians.</p>
            
            <br />
            <br />
          </div>
        </ScrollParallax>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/alpaca_actual.png"
              srcSetDark="/images/content/alpaca_actual.png"
              src="/images/content/alpaca_actual.png"
              srcDark="/images/content/alpaca_actual.png"
              alt="App"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
