import React, { useRef } from "react";
// import styles from "./Home.module.sass";
import Hero from "./Hero";

const Newsletter = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero scrollToRef={scrollToRef} />
    </>
  );
};

export default Newsletter;
