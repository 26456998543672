import React from "react";
import Post from "./Plan";
import Offer from "../../../components/Offer";


const Blog3 = () => {
  return (
    <>
      <Post />
      <Offer />
    </>
  );
};

export default Blog3;
