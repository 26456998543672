import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Iframe from 'react-iframe'


const Hero = ({ scrollToRef }) => {
  const iframeRef = React.createRef();
  const [iframeHeight, setIframeHeight] = React.useState('100%');

  return (
    <div>
        <Iframe
            url='https://share.hsforms.com/1VMbmxCEiQwWMWDAWxsOyawdtujr'
            width="100%"
            height="1200px"
            id=""
            className=""
            display="block"
            overflow="scroll"
            position="relative"
        />
    </div>
  );
};

export default Hero;
