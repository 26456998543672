import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Get Early Access",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "To get started, please request access. Once you're off the waitlist, we'll facilitate a free 1:1 and confidential call with an Alpaca team member to understand your unique journey. Our plans can be tailored for those with high cortisol levels, high Cholesterol, etc."
  },
  {
    title: "Order Baseline Test Kit",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "Alpaca will work with you to order an at-home test kit and provide steps to setup a controlled baseline. CLIA-certified at-home tests requiring finger pricks will be sent once a month and we'll work with you to personalize your plan."
  },
  {
    title: "Proactive Health Plan",
    color: "#EF466F",
    images: "/images/content/medal.svg",
    content:
      "Once we establish baselines and test kits, we will work with you to identify lifestyle changes to lower inflammation and key biomarkers. By combining at-home tests, Apple Health, non-invasive biomarkers, and behavioral science, we can help you take control and act on insights."
  },
  {
    title: "Personalized Lifestyle Medicine",
    color: "#45B26B",
    images: "/images/content/stopwatch.svg",
    content:
      "We take a medical approach that uses evidence-based behavioral interventions to prevent, treat and manage chronic diseases and Long COVID. Our team will work with you to tailor behavioral cues to set you up for success through SMS-based reminders and weekly check-ins."
  }
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How Alpaca Works</h2>
          <div className={styles.info}>
            Alpaca is your companion to monitor your inflammation
            levels, take control of your health 🦙
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
